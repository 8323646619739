import React from "react";

import Footer from "../components/layout/Footer";
import Navbar from "../components/layout/Navbar";
import ResetPassword from "../components/password/ResetPassword";

const ForgotPasswordPage = () => {
  return (
    <div>
      <Navbar />
      <ResetPassword />
      <Footer />
    </div>
  );
};

export default ForgotPasswordPage;
