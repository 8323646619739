// AUTH TYPES
export const REGISTER_SUCCESSS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_UNAPPROVED_LECTURERS = "GET_UNAPPROVED_LECTURERS";
export const UNAPPROVED_LECTURERS_ERROR = "UNAPPROVED_LECTURERS_ERROR";
export const LECTURER_APPROVAL_SUCCESS = "LECTURER_APPROVAL_SUCCESS";
export const LECTURER_APPROVAL_FAIL = "LECTURER_APPROVAL_FAIL";
export const PASSWORD_UPDATE_SUCCESS = "PASSWORD_UPDATE_SUCCESS";
export const PASSWORD_UPDATE_FAIL = "PASSWORD_UPDATE_FAIL";
export const PASSWORD_REQ_SUCCESS = "PASSWORD_REQ_SUCCESS";
export const PASSWORD_REQ_FAIL = "PASSWORD_REQ_FAIL";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";

// ALERT TYPES
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// QUESTION
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS";
export const ADD_QUESTION_FAIL = "ADD_QUESTION_FAIL";
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";
export const GET_QUESTIONS_FAIL = "GET_QUESTIONS_FAIL";

// EXAM
export const ADD_EXAM_SUCCESS = "ADD_EXAM_SUCCESS";
export const ADD_EXAM_FAIL = "ADD_EXAM_FAIL";
export const GET_EXAMS_SUCCESS = "GET_EXAMS_SUCCESS";
export const GET_EXAMS_FAIL = "GET_EXAMS_FAIL";
export const GET_EXAM_SUCCESS = "GET_EXAM_SUCCESS";
export const GET_EXAM_FAIL = "GET_EXAM_FAIL";
export const ADD_QUESTION_TO_EXAM = "ADD_QUESTION_TO_EXAM";
export const REMOVE_QUESTION_FROM_EXAM = "REMOVE_QUESTION_FROM_EXAM";
export const GET_LECTURER_EXAMS_SUCCESS = "GET_LECTURER_EXAMS_SUCCESS";
export const GET_LECTURER_EXAMS_FAIL = "GET_LECTURER_EXAMS_FAIL";
export const ADD_QUESTIONS_TO_EDIT_EXAM = "ADD_QUESTIONS_TO_EDIT_EXAM";
export const EDIT_EXAM_SUCCESS = "EDIT_EXAM_SUCCESS";

// REVISION
export const ADD_REVISION_SUCCESS = "ADD_REVISION_SUCCESS";
export const ADD_REVISION_FAIL = "ADD_REVISION_FAIL";
export const GET_REVISIONS_SUCCESS = "GET_REVISIONS_SUCCESS";
export const GET_REVISIONS_FAIL = "GET_REVISIONS_FAIL";
export const GET_REVISION_SUCCESS = "GET_REVISION_SUCCESS";
export const GET_REVISION_FAIL = "GET_REVISION_FAIL";
export const GET_LECTURER_REVISIONS_SUCCESS = "GET_LECTURER_REVISIONS_SUCCESS";
export const GET_LECTURER_REVISIONS_FAIL = "GET_LECTURER_REVISIONS_FAIL";
export const EDIT_REVISION_SUCCESS = "EDIT_REVISION_SUCCESS";
export const ADD_QUESTION_TO_REVISION = "ADD_QUESTION_TO_REVISION";
export const REMOVE_QUESTION_FROM_REVISION = "REMOVE_QUESTION_FROM_REVISION";
export const ADD_QUESTIONS_TO_EDIT_REVISION = "ADD_QUESTIONS_TO_EDIT_REVISION";

// ANSWERS
export const ADD_EXAM_ANSWERS_SUCCESS = "ADD_EXAM_ANSWERS_SUCCESS";
export const ADD_RESULTS_SUCCESS = "ADD_RESULTS_SUCCESS";
export const ADD_EXAM_ANSWERS_FAIL = "ADD_EXAM_ANSWERS_FAIL";
export const GET_EXAM_ANSWERS_SUCCESS = "GET_EXAM_ANSWERS_SUCCESS";
export const GET_EXAM_ANSWERS_FAIL = "GET_EXAM_ANSWERS_FAIL";
export const GET_EXAMS_ANSWERS_SUCCESS = "GET_EXAMS_ANSWERS_SUCCESS";
export const GET_EXAMS_ANSWERS_FAIL = "GET_EXAMS_ANSWERS_FAIL";
export const ABANDON_EXAM = "ABANDON_EXAM";
export const UNDO_EXAM_ANS = "UNDO_EXAM_ANS";
export const GET_LEC_EXAM_ANS_SUCCESS = "GET_LEC_EXAM_ANS_SUCCESS";
export const GET_LEC_EXAM_ANS_FAIL = "GET_LEC_EXAM_ANS_FAIL";
export const DELETE_ANSSHEET_SUCCESS = "DELETE_ANSSHEET_SUCCESS";
export const DELETE_ANSSHEET_FAIL = "DELETE_ANSSHEET_FAIL";

// REVISION ANSWERS
export const ADD_REVISION_ANSWERS_SUCCESS = "ADD_REVISION_ANSWERS_SUCCESS";
export const ADD_REVISION_RESULTS_SUCCESS = "ADD_REVISION_RESULTS_SUCCESS";
export const ADD_REVISION_ANSWERS_FAIL = "ADD_REVISION_ANSWERS_FAIL";
export const GET_REVISION_ANSWERS_SUCCESS = "GET_REVISION_ANSWERS_SUCCESS";
export const GET_REVISION_ANSWERS_FAIL = "GET_REVISION_ANSWERS_FAIL";
export const GET_REVISIONS_ANSWERS_SUCCESS = "GET_REVISIONS_ANSWERS_SUCCESS";
export const GET_REVISIONS_ANSWERS_FAIL = "GET_REVISIONS_ANSWERS_FAIL";
export const ABANDON_REVISION = "ABANDON_REVISION";
export const UNDO_REVISION_ANS = "UNDO_REVISION_ANS";
export const GET_LEC_REVISION_ANS_SUCCESS = "GET_LEC_REVISION_ANS_SUCCESS";
export const GET_LEC_REVISION_ANS_FAIL = "GET_LEC_REVISION_ANS_FAIL";
export const DELETE_REVISION_ANSSHEET_SUCCESS =
  "DELETE_REVISION_ANSSHEET_SUCCESS";
export const DELETE_REVISION_ANSSHEET_FAIL = "DELETE_REVISION_ANSSHEET_FAIL";

// REGISTRATION FOR EXAMS
export const ADD_REG_EXAM_SUCCESS = "ADD_REG_EXAM_SUCCESS";
export const ADD_REG_EXAM_FAIL = "ADD_REG_EXAM_FAIL";
export const GET_REG_EXAMS_SUCCESS = "GET_REG_EXAMS_SUCCESS";
export const GET_REG_EXAMS_FAIL = "GET_REG_EXAMS_FAIL";
export const GET_EXAM_REGS_SUCCESS = "GET_EXAM_REGS_SUCCESS";
export const GET_EXAM_REGS_FAIL = "GET_EXAM_REGS_FAIL";
export const DELETE_EXAM_REG_SUCCESS = "DELETE_EXAM_REG_SUCCESS";
export const DELETE_EXAM_REG_FAIL = "DELETE_EXAM_REG_FAIL";

// REGISTRATION FOR REVISIONS
export const ADD_REG_REVISION_SUCCESS = "ADD_REG_REVISION_SUCCESS";
export const ADD_REG_REVISION_FAIL = "ADD_REG_REVISION_FAIL";
export const GET_REG_REVISIONS_SUCCESS = "GET_REG_REVISIONS_SUCCESS";
export const GET_REG_REVISIONS_FAIL = "GET_REG_REVISIONS_FAIL";
export const GET_REVISION_REGS_SUCCESS = "GET_REVISION_REGS_SUCCESS";
export const GET_REVISION_REGS_FAIL = "GET_REVISION_REGS_FAIL";
export const DELETE_REVISION_REG_SUCCESS = "DELETE_REVISION_REG_SUCCESS";
export const DELETE_REVISION_REG_FAIL = "DELETE_REVISION_REG_FAIL";
