import React, { useReducer } from "react";
// import { useNavigate } from "react-router-dom";
import axios from "axios";

import RegRevisionContext from "./RegRevisionContext";
import RegRevisionReducer from "./RegRevisionReducer";

import {
  ADD_REG_REVISION_SUCCESS,
  ADD_REG_REVISION_FAIL,
  GET_REG_REVISIONS_SUCCESS,
  GET_REG_REVISIONS_FAIL,
  GET_REVISION_REGS_SUCCESS,
  GET_REVISION_REGS_FAIL,
  DELETE_REVISION_REG_SUCCESS,
  DELETE_REVISION_REG_FAIL,
} from "../types";

const RegRevisionState = (props) => {
  // const navigate = useNavigate();

  const initialState = {
    registerRevisions: [],
    revisionRegistrations: [],
    error: null,
  };

  const [state, dispatch] = useReducer(RegRevisionReducer, initialState);

  // MongoDB Actions

  // add new question
  async function addRegisterRevision(registerRevision) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // console.log("logging from state side");
    try {
      const res = await axios.post(
        "/api/v1/registerforrevisions",
        registerRevision,
        config
      );

      // console.log(res.data.data.data);

      // for inApp display of results
      dispatch({
        type: ADD_REG_REVISION_SUCCESS,
        payload: res.data.data.data,
      });

      // setTimeout(() => {
      //   navigate("/exams", { replace: true });
      // }, 1000);
    } catch (error) {
      // console.log(error);
      dispatch({
        type: ADD_REG_REVISION_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // get all exam
  async function getAllRegRevisions(userId) {
    const searchParams = userId ? "?userId=" + userId : "";
    // console.log("logging from state side");
    try {
      const res = await axios.get(
        `/api/v1/registerforrevisions${searchParams}`
      );
      // console.log(res.data.data.data);

      dispatch({
        type: GET_REG_REVISIONS_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_REG_REVISIONS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }
  // get all lecturer exams
  async function getAllRevisionRegs(examId) {
    const searchParams = examId ? "?examId=" + examId : "";
    // console.log("logging from state side");
    try {
      const res = await axios.get(
        `/api/v1/registerforrevisions${searchParams}`
      );
      // console.log(res.data.data.data);

      dispatch({
        type: GET_REVISION_REGS_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_REVISION_REGS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // delete registered student
  async function deRegisterStudent(registrationId) {
    try {
      // const res =
      await axios.delete(`/api/v1/registerforrevisions/${registrationId}`);
      // console.log(res.data.data.data);

      dispatch({
        type: DELETE_REVISION_REG_SUCCESS,
        payload: registrationId,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: DELETE_REVISION_REG_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  return (
    <RegRevisionContext.Provider
      value={{
        registerRevisions: state.registerRevisions,
        revisionRegistrations: state.revisionRegistrations,
        error: state.error,
        addRegisterRevision,
        getAllRevisionRegs,
        getAllRegRevisions,
        deRegisterStudent,
      }}
    >
      {props.children}
    </RegRevisionContext.Provider>
  );
};

export default RegRevisionState;
