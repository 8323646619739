import React, { useEffect } from "react";

import useAuth from "../hooks/useAuth";
import Footer from "./layout/Footer";
import Navbar from "./layout/Navbar";

const Verify = () => {
  const { loadUser } = useAuth;
  useEffect(() => {
    // wrap your async call here
    const loadUserData = async () => {
      await loadUser();
    };

    // then call it here
    loadUserData();
    // loadUser();
    // eslint-disable-next-line
  }, []);

  let firstName;

  // if (user) firstName = user.data.name.split(" ")[0];

  // console.log(user);

  return (
    <div>
      <Navbar />
      <div className="relative container mx-auto  min-h-screen flex flex-col  py-12 px-6 lg:px-8">
        <h3>Welcome {firstName}</h3>
        <p>
          Your registration is almost complete. Kindly check your email for a
          verification link.
        </p>
        <p>If this account is not verified within 24hrs, it will be erased.</p>
      </div>

      <Footer />
    </div>
  );
};

export default Verify;
