import React, { useContext } from "react";

import WelcomeBanner from "../components/layout/WelcomeBanner";
import EditPassword from "../components/password/EditPassword";
import AuthContext from "../context/auth/AuthContext";

const AccountPage = () => {
  const authContext = useContext(AuthContext);

  return (
    <>
      {/* Welcome banner */}
      <WelcomeBanner
        user={authContext.user}
        msg={"Here ypu can edit your settings:"}
      />
      <EditPassword />
    </>
  );
};

export default AccountPage;
