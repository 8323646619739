import React, { useEffect, useContext } from "react";
import ApproveLecturer from "../components/layout/ApproveLecturer";

import WelcomeBanner from "../components/layout/WelcomeBanner";

import AuthContext from "../context/auth/AuthContext";

const AdminDashboardPage = () => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.loadUser();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* Welcome banner */}
      <WelcomeBanner user={authContext.user} />
      <ApproveLecturer />
    </>
  );
};

export default AdminDashboardPage;
