import React from "react";
import logo from "./../../img/examaholics-logo.svg";

const Footer = () => {
  return (
    <div>
      <footer className="bg-veryDarkBlue">
        <div className="container flex flex-col-reverse justify-between px-6 py-10 mx-auto space-y-8 md:flex-row md:space-y-0">
          {/* logo and social links */}
          <div className="flex flex-col-reverse items-center justify-between space-y12 md:flex-col md:space-y-0 md:items-start">
            <div className="mx-auto my-6 text-center text-white md:hidden">
              Copyright &copy; 2022, All Rights Reserved
            </div>
            {/* logo */}
            <div>
              <img src={logo} className="h-12" alt="" />
            </div>
            {/* social medial links */}
            <div className="flex justify-center space-x-4">
              {/* link 1 */}
              <a href="">
                <img src="" className="h-8" alt="" />
              </a>
              {/* link 1 */}
              <a href="">
                <img src="" className="h-8" alt="" />
              </a>
              {/* link 1 */}
              <a href="">
                <img src="" className="h-8" alt="" />
              </a>
              {/* link 1 */}
              <a href="">
                <img src="" className="h-8" alt="" />
              </a>
            </div>
          </div>
          {/* list container */}
          <div className="flex justify-around space-x-32">
            <div className="flex flex-col space-y-3 text-white">
              <a href="#" className="hover:text-brightRed">
                Home
              </a>
              <a href="#" className="hover:text-brightRed">
                Pricing
              </a>
              <a href="#" className="hover:text-brightRed">
                Products
              </a>
            </div>
            <div className="flex flex-col space-y-3 text-white">
              <a href="#" className="hover:text-brightRed">
                About us
              </a>
              <a href="#" className="hover:text-brightRed">
                Cereers
              </a>
              <a href="policy" className="hover:text-brightRed">
                Privacy Policy
              </a>
            </div>
          </div>
          {/* Input container */}
          <div className="flex flex-col justify-between">
            <form className="flex flex-col">
              <div className="flex space-x-3">
                <input
                  type="text"
                  className="flex-1 px-4 rounded-full focus:outline-none"
                  placeholder="Email"
                />
                <button className="px-6 py-2 text-white rounded-full bg-brightRed hover:bg-brightRedLight focus:outline-none">
                  Go
                </button>
              </div>
            </form>
            <div className="hidden text-white md:block">
              Copyright &copy; 2022, All Rights Reserved
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
