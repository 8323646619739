import React from "react";

import WelcomeBanner from "../components/layout/WelcomeBanner";

import useAuth from "../hooks/useAuth";
import ExamsList from "../components/layout/ExamsList";

const ExamsListPage = () => {
  const { user } = useAuth();

  return (
    <>
      {/* Welcome banner */}
      <WelcomeBanner user={user} msg={"See listed exams here:"} />
      {/* <ExamLinks /> */}
      <ExamsList />
    </>
  );
};

export default ExamsListPage;
