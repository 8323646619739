import React, { useReducer } from "react";
import axios from "axios";

import QuestionContext from "./QuestionContext";
import QuestionReducer from "./QuestionReducer";

import {
  ADD_QUESTION_SUCCESS,
  ADD_QUESTION_FAIL,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_FAIL,
} from "../types";

const QuestionState = (props) => {
  const initialState = {
    questions: [],
    error: null,
  };

  const [state, dispatch] = useReducer(QuestionReducer, initialState);

  // MongoDB Actions

  // add new question
  async function addQuestion(question) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // console.log("logging from state side");
    try {
      const res = await axios.post("/api/v1/questions", question, config);
      // console.log(res);

      dispatch({
        type: ADD_QUESTION_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: ADD_QUESTION_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // get all questions
  async function getQuestions() {
    // console.log("logging from state side");
    try {
      const res = await axios.get("/api/v1/questions");
      // console.log(res.data.data.data);

      dispatch({
        type: GET_QUESTIONS_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_QUESTIONS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // get all lecturer questions
  async function getLecQuestions(lecturerId) {
    const searchParams = lecturerId ? "?createdBy=" + lecturerId : "";
    // console.log("logging from state side");
    try {
      const res = await axios.get(`/api/v1/questions${searchParams}`);
      // console.log(res.data.data.data);

      dispatch({
        type: GET_QUESTIONS_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_QUESTIONS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  return (
    <QuestionContext.Provider
      value={{
        questions: state.questions,
        error: state.error,
        addQuestion,
        getQuestions,
        getLecQuestions,
      }}
    >
      {props.children}
    </QuestionContext.Provider>
  );
};

export default QuestionState;
