import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import "./App.css";
import setAuthToken from "./utils/setAuthToken";
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import Verify from "./components/Verify";
import RegisterPage from "./pages/RegisterPage";
import Layout from "./components/layout/Layout";
import DashboardPage from "./pages/DashboardPage";
import AdminDashboardPage from "./pages/AdminDashboardPage";
import RequireAuth from "./components/routing/RequireAuth";
import Unauthorized from "./pages/Unauthorized";
import MissingPage from "./pages/MissingPage";
import AddQuestionPage from "./pages/AddQuestionPage";
import CreateExamPage from "./pages/CreateExamPage";
import LecturerPage from "./pages/LecturerPage";
import ExamsListPage from "./pages/ExamsListPage";
import ExamPage from "./pages/ExamPage";
import ExamResultsPage from "./pages/ExamResultsPage";
import ExamsResultsPage from "./pages/ExamsResultsPage";
import ExamRegisteredPage from "./pages/ExamRegisteredPage";
import SharedDashBoardLayouts from "./components/layout/SharedDashBoardLayouts";
import ExamsReportsPage from "./pages/ExamsReportsPage";
import RevisePage from "./pages/RevisePage";
import ComingSoonPage from "./pages/ComingSoonPage";
import VerifyUser from "./components/VerifyUser";
import { PersistLogin } from "./components/PersistLogin";
import NotVerifiedPage from "./pages/NotVerifiedPage";
import PrivateVerifiedRoute from "./components/routing/PrivateVerifiedRoute";
import EditExamPage from "./pages/EditExamPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import AccountPage from "./pages/AccountPage";
import LecExamResultsPage from "./pages/LecExamResultsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import RegisterLecturerPage from "./pages/RegisterLecturerPage";
import CreateRevisionPage from "./pages/CreateRevisionPage";
import RevisionsListPage from "./pages/RevisionsListPage";
import RevisionPage from "./pages/RevisionPage";
import RevisionResultsPage from "./pages/RevisionResultsPage";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const ROLES = {
  User: "user",
  Lecturer: "lecturer",
  Admin: "admin",
};

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* puplic routes */}
        <Route path="" element={<LandingPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="registerlecturer" element={<RegisterLecturerPage />} />
        <Route path="policy" element={<PrivacyPolicyPage />} />
        <Route path="forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="resetpassword/:token" element={<ResetPasswordPage />} />

        {/* User verification */}
        <Route path="verify" element={<Verify />} />
        <Route path="verify/:userId" element={<VerifyUser />} />

        {/* Dashboard Shared Layout Routes */}
        <Route path="/" element={<SharedDashBoardLayouts />}>
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="notverified" element={<NotVerifiedPage />} />

          {/* Persist Login for Protected Elements */}
          <Route element={<PersistLogin />}>
            {/* Check is verified Protected Elements */}
            <Route element={<PrivateVerifiedRoute />}>
              {/* protected routes  */}
              <Route
                element={
                  <RequireAuth
                    allowedRoles={[ROLES.User, ROLES.Lecturer, ROLES.Admin]}
                  />
                }
              >
                <Route path="/dashboard" element={<DashboardPage />} />
              </Route>
              <Route
                element={
                  <RequireAuth
                    allowedRoles={[ROLES.User, ROLES.Lecturer, ROLES.Admin]}
                  />
                }
              >
                <Route path="/students">
                  <Route path="exams" element={<ExamsListPage />} />
                  <Route path="revise" element={<RevisionsListPage />} />
                  <Route path="exams/:id" element={<ExamPage />} />
                  <Route path="revisions/:id" element={<RevisionPage />} />
                  <Route path="results" element={<ExamResultsPage />} />
                  <Route path="examsresults" element={<ExamsResultsPage />} />
                  <Route
                    path="revisionresults"
                    element={<RevisionResultsPage />}
                  />
                  {/* <Route path="revise" element={<RevisePage />} /> */}
                  <Route path="account" element={<AccountPage />} />
                </Route>

                <Route path="/comingsoon" element={<ComingSoonPage />} />
              </Route>
              <Route
                element={
                  <RequireAuth allowedRoles={[ROLES.Lecturer, ROLES.Admin]} />
                }
              >
                <Route path="/lecturers">
                  <Route path="addquestion" element={<AddQuestionPage />} />
                  <Route path="createexam" element={<CreateExamPage />} />
                  <Route
                    path="createrevision"
                    element={<CreateRevisionPage />}
                  />
                  <Route path="examsreports" element={<ExamsReportsPage />} />
                  <Route path="exams/:id" element={<ExamRegisteredPage />} />
                  <Route
                    path="examresults/:id"
                    element={<LecExamResultsPage />}
                  />
                  <Route path="editexam/:id" element={<EditExamPage />} />
                  <Route path="lecturer" element={<LecturerPage />} />
                </Route>
              </Route>
              <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                <Route path="/admin" element={<AdminDashboardPage />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      {/* catch all */}
      <Route path="*" element={<MissingPage />} />
    </Routes>
  );
}

export default App;
