// calculate grade LMMU
// Percentage Grade >=80, <80 - >=70 , <70 - >=60, <60 - >=55, <55 - >=50, <50 - >=45, <45
// Letter Grade A, B+, B, C+, C, D+, D
// Grade Point 4, 3.5, 3, 2, 1, 0, 0
// Grade Point Distinction , Meritorious , Good , Clear Pass , Pass , Supplementary , Fail

export function letterGradeLMMU(percetageScore) {
  let grade = "";
  if (percetageScore >= 80) {
    grade = `A, 4, Distinction`;
  } else if (percetageScore >= 70) {
    grade = `B+, 3.5, Meritorious`;
  } else if (percetageScore >= 60) {
    grade = `B, 3, Good`;
  } else if (percetageScore >= 55) {
    grade = `C+, 2, Clear Pass`;
  } else if (percetageScore >= 50) {
    grade = `C, 1, Pass`;
  } else if (percetageScore >= 45) {
    grade = `D+, 0, Supplementary`;
  } else if (percetageScore >= 0) {
    grade = `D, 0, Fail`;
  }
  return grade;
}
