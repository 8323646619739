import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import useAuth from "./../hooks/useAuth";
import AlertContext from "../context/alert/AlertContext";
import Alerts from "./layout/Alerts";

import logo from "./../img/examaholics-logo.svg";

// import { GlobalContext } from "../context/GlobalState";

const Login = (props) => {
  const alertContext = useContext(AlertContext);

  let navigate = useNavigate();

  const { setAlert } = alertContext;
  const { login, error, clearErrors, loading, isAuthenticated } = useAuth();

  const [isDisabled, setIsDisabled] = useState(false);

  // console.log(user);
  // useEffect(() => {
  //   localStorage.token && isAuthenticated = navigate("/dashboard");
  // }, []);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    if (isMounted && !loading && isAuthenticated) {
      navigate("/dashboard");
    }
    if (error === "Incorrect email or password") {
      // console.log(error);
      setIsDisabled(false);
      setAlert(error, "danger");
      clearErrors();
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line
  }, [isAuthenticated, error]);

  const [formUser, setFormUser] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formUser;

  const onChange = (e) =>
    setFormUser({ ...formUser, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      setAlert("Please enter all fields", "danger");
    } else {
      login({ email, password });
      setIsDisabled(true);
    }
  };

  return (
    <div className="min-h-screen flex flex-col  py-12 px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={logo} alt="Examaholics" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {/* Create your account */}
          Log into your account
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600 max-w">
          Don't have an account?
          {/* Already registered? */}
          <Link
            to="/Register"
            className="font-medium text-brightRed hover:text-brightRedLight focus:outline-none focus:ring-2 focus:ring-brightRedLight"
          >
            {" "}
            Register
            {/* Sign in */}
          </Link>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="mx-auto">
          {/* Alerts */}
          <Alerts />
        </div>

        <div className="bg-white py-8 px-6 shadow rounded-3xl sm:px-10">
          <form
            onSubmit={onSubmit}
            className="mb-0 space-y-6"
            // action="#"
            // method="POST"
          >
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={onChange}
                  placeholder="Enter email..."
                  required
                  className=""
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={onChange}
                  placeholder="Enter password..."
                  required
                  className=""
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-brightRed hover:bg-brightRedLight focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brightRedSupLight"
                disabled={isDisabled}
              >
                Sign in
              </button>
            </div>
          </form>
          <div className="mt-2">
            <Link to={`/forgotpassword`}>
              <button className="mt-2 text-center text-sm text-gray-600 max-w">
                Forgot your password?{" "}
                <span className="underline text-brightRed hover:text-brightRedLight">
                  click here
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
