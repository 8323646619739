import React, { useContext } from "react";

import WelcomeBanner from "../components/layout/WelcomeBanner";

import AuthContext from "../context/auth/AuthContext";

import CreateExam from "../components/layout/CreateExam";

const CreateExamPage = () => {
  const authContext = useContext(AuthContext);

  return (
    <>
      {/* Welcome banner */}
      <WelcomeBanner
        user={authContext.user}
        msg={"You can create your exam from here:"}
      />

      {/* add quesion form */}
      <CreateExam user={authContext.user} />
    </>
  );
};

export default CreateExamPage;
