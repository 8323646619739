import React, { useState, useContext, useEffect } from "react";
import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import ExamControlButton from "../reusableComponents/ExamControlButton";

const EditPassword = (props) => {
  const { user, updatePassword, error, clearErrors } = useAuth();

  // console.log(user);

  const [isAddingTransaction, setIsAddingTransaction] = useState(false);

  const { setAlert } = useAlert();

  useEffect(() => {
    if (error === "Duplicate field value entered") {
      setAlert(error, "danger");
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error, props.history]);

  const [passwords, setPasswords] = useState({
    currentPassword: "",
    password: "",
    password2: "",
  });

  const { currentPassword, password, password2 } = passwords;

  const onChange = (e) =>
    setPasswords({ ...passwords, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (currentPassword === "" || password === "") {
      setAlert("Please enter all fields", "danger");
    } else if (password !== password2) {
      setAlert("New passwords don't match", "danger");
    } else {
      updatePassword({
        userId: user._id,
        currentPassword,
        password,
        password2,
      });
      setAlert("Password successfully updated", "success");
      setIsAddingTransaction(!isAddingTransaction);
      setPasswords({
        currentPassword: "",
        password: "",
        password2: "",
      });
    }
  };

  const toggleForm = () => {
    setIsAddingTransaction(!isAddingTransaction);
  };

  return (
    <div className="min-h-screen flex flex-col   ">
      <div className=" sm:w-full sm:max-w-md">
        <h2 className="mt-6  text-3xl font-extrabold text-gray-900">
          Edit password
        </h2>
        {isAddingTransaction && (
          <form className="space-y-6 mt-6" onSubmit={onSubmit}>
            <div className="form-control">
              <label htmlFor="currentPassword">Current password</label>
              <input
                type="password"
                name="currentPassword"
                value={currentPassword}
                onChange={onChange}
                required
                minLength="6"
                placeholder="Enter current password..."
              />
            </div>

            <div className="form-control">
              <label htmlFor="password">New password</label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={onChange}
                required
                minLength="6"
                placeholder="Enter new password..."
              />
            </div>
            <div className="form-control">
              <label htmlFor="password2">Confirm new password</label>
              <input
                type="password"
                name="password2"
                value={password2}
                onChange={onChange}
                required
                minLength="6"
                placeholder="confirm new password..."
              />
            </div>
            {/* <button className="btn">Confirm</button> */}
            <div className="mt-6">
              <ExamControlButton text={"Confirm"} />
            </div>
          </form>
        )}
        {/* <button
          onClick={toggleForm}
          className={isAddingTransaction ? "btn-cancel" : "btn"}
        >
          {isAddingTransaction ? "Cancel" : "Update password"}
        </button> */}
        <div className="mt-6">
          <ExamControlButton
            onClick={toggleForm}
            text={isAddingTransaction ? "Cancel" : "Update password"}
            bgColor={isAddingTransaction ? "bg-gray-200" : ""}
            textColor={isAddingTransaction ? "text-gray-800" : ""}
          />
        </div>
      </div>
    </div>
  );
};

export default EditPassword;
