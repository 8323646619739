import React, { useEffect } from "react";
// import { simplifiedMilliseconds } from "../utils/simplifyMilliseconds";

import useExamAns from "./../hooks/useExamAns";
import useAuth from "./../hooks/useAuth";
import { simplifiedMilliseconds } from "../utils/simplifyMilliseconds";

const ExamsResultsList = () => {
  const { user } = useAuth();
  const { examAnswerSheets, getAllExamAnswerSheets } = useExamAns();

  useEffect(() => {
    getAllExamAnswerSheets(user._id);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="border rounded-3xl  bg-veryLightGray shadow-md overflow-hidden">
      <div className="bg-brightRedSupLight">
        <div className=" py-4 px-6 text-2xl md:text-3xl text-slate-800 font-bold mb-1">
          <h1>Results</h1>
        </div>
      </div>

      <div className="overflow-auto">
        <table className="w-full">
          <thead className="bg-gray-50 border-b-2 border-gray-200 ">
            <tr>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">
                Title
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Score
              </th>
              <th className="  w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Grade
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Time
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Questions
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Correct
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Incorrect
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Skipped
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Date
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {examAnswerSheets !== [] && Array.isArray(examAnswerSheets)
              ? examAnswerSheets?.map((ansSheet, index) => {
                  const finishedAt = new Date(ansSheet.finishedAt);
                  const startedAt = new Date(ansSheet.startedAt);

                  const timeTaken = simplifiedMilliseconds(
                    Math.abs(finishedAt - startedAt)
                  );

                  // covert date string to datetime
                  const finTime = `${finishedAt.toDateString()} ${finishedAt.toLocaleTimeString()}`;

                  // console.log(ansSheet.title);

                  // is index even
                  const isEven = index % 2 === 0;

                  return (
                    <tr
                      key={ansSheet._id}
                      className={`${isEven ? "bg-white" : "bg-gray-50"}`}
                    >
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {ansSheet.title}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {ansSheet.score}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        <span
                          className={`p-1.5 text-sm font-medium uppercase tracking-wider ${
                            ansSheet.score >= 50
                              ? " text-green-800 bg-green-200"
                              : " text-red-800 bg-red-200"
                          } rounded-lg bg-opacity-50`}
                        >
                          {ansSheet.grade.split(",")[0]}
                        </span>
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {timeTaken}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {ansSheet.numQuestions}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {ansSheet.numCorrectAnswers}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {ansSheet.numWrongAnswers}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {ansSheet.unattempted}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {finTime}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>

      <div className="hidden px-6 py-2 mb-2">Actions</div>
    </div>
  );
};

export default ExamsResultsList;
