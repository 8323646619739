import React from "react";
import { simplifiedMilliseconds } from "../utils/simplifyMilliseconds";

import useExamAns from "./../hooks/useExamAns";

const ExamResults = () => {
  const { results } = useExamAns();

  const finishedAt = new Date(results?.finishedAt);
  const startedAt = new Date(results?.startedAt);

  const timeTaken = Math.abs(finishedAt - startedAt);

  // covert date string to datetime
  const finTime = `${finishedAt.toDateString()} ${finishedAt.toLocaleTimeString()}`;

  return (
    <div className="border rounded-3xl  bg-veryLightGray shadow-md overflow-hidden">
      <div className="bg-brightRedSupLight">
        <div className=" py-4 px-6 text-2xl md:text-3xl text-slate-800 font-bold mb-1">
          <h1>Results</h1>
        </div>
      </div>
      <div className="mb-6">
        <div className="px-6 py-2">
          <h2 className="text-lg font-bold ">{results?.title}</h2>
        </div>
        <div className="px-6">
          <div className="flex flex-row items-center">
            <div className="">Overall score:</div>
            <div className="px-4 text-xl font-bold">{results?.score}%</div>
          </div>
          <div className="flex flex-row items-center">
            <div className="">Grade:</div>
            <div
              className={`px-4 text-xl font-bold ${
                results?.score >= 50 ? " text-green-800" : " text-red-800"
              }`}
            >
              {results?.grade.split(",")[0]}
            </div>
          </div>
          <div className="flex flex-row items-center">
            <div>Time taken:</div>
            <div className="px-4">{simplifiedMilliseconds(timeTaken)}</div>
          </div>
          <div className="flex flex-row items-center">
            <div>Questions asked:</div>
            <div className="px-4">{results?.numQuestions}</div>
          </div>
          <div className="flex flex-row items-center">
            <div>Correct:</div>
            <div className="px-4">{results?.numCorrectAnswers}</div>
          </div>
          <div className="flex flex-row items-center">
            <div>Incorrect:</div>
            <div className="px-4">{results?.numWrongAnswers}</div>
          </div>
          <div className="flex flex-row items-center">
            <div> Unattempted:</div>
            <div className="px-4">{results?.unattempted}</div>
          </div>
          <div className="flex flex-row items-center">
            <div>Completed:</div>
            <div className="px-4">{finTime}</div>
          </div>
        </div>
      </div>
      <div className="hidden px-6 py-2 mb-2">Actions</div>
    </div>
  );
};

export default ExamResults;
