import React, { useContext } from "react";

import WelcomeBanner from "../components/layout/WelcomeBanner";
import AuthContext from "../context/auth/AuthContext";

const DashboardPage = () => {
  const authContext = useContext(AuthContext);

  return <WelcomeBanner user={authContext.user} />;
};

export default DashboardPage;
