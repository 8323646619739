import React from "react";
import ExamControlButton from "../reusableComponents/ExamControlButton";

const AbandonExamModal = ({
  visible,
  onClose,
  onAbandon,
  numQuestions,
  numAnswered,
}) => {
  const handleOnClose = (e) => {
    if (e.target.id === "modelContainer") onClose();
  };
  if (!visible) return null;
  return (
    <div
      id="modelContainer"
      onClick={handleOnClose}
      className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center"
    >
      {/* modelContainer */}

      <div className="bg-white flex flex-col  p-6 rounded-lg">
        {/* title and close btn */}
        <div className="flex justify-end">
          <button className="bg-transparent text-lg" onClick={onClose}>
            X
          </button>
        </div>
        {/* title */}
        <div className="block mt-2">
          <h1 className="font-bold text-lg mb-4">
            Are you sure you want to Abort?
          </h1>
        </div>
        {/* body */}
        <div className="flex flex-col justify-center items-center text-center text-lg mb-2">
          <p>
            You have answered {numAnswered} of {numQuestions} questions.
          </p>
          <p>Abort to quit this exam.</p>
        </div>
        {/* footer */}
        <div className="flex justify-evenly mt-4 items-center">
          <ExamControlButton
            bgColor={"bg-brightRedSupLight"}
            textColor={"text-gray-600"}
            text={"Go Back"}
            onClick={onClose}
          />
          <ExamControlButton onClick={onAbandon} text={"Abort"} />
        </div>
      </div>
    </div>
  );
};

export default AbandonExamModal;
