import React, { useContext } from "react";

import WelcomeBanner from "../components/layout/WelcomeBanner";
import AuthContext from "../context/auth/AuthContext";

import ExamResults from "./ExamResults";

const ExamResultsPage = () => {
  const authContext = useContext(AuthContext);

  return (
    <>
      {/* Welcome banner */}
      <WelcomeBanner user={authContext.user} msg={"Here are your results:"} />
      <ExamResults />
    </>
  );
};

export default ExamResultsPage;
