import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isHamburgerBtn, setHamburgerBtn] = useState("");
  const [isMobileMenu, setMobileMenu] = useState("hidden");

  const toggleHamburgerMenu = () => {
    if (isHamburgerBtn === "") {
      setHamburgerBtn("open");
      setMobileMenu("flex");
    } else {
      setHamburgerBtn("");
      setMobileMenu("hidden");
    }
  };
  return (
    <div className="relative container mx-auto p-6 ">
      <div className="flex items-center justify-between">
        {/* logo */}
        <div className="pt-2">
          <h1 className="text-3xl">Examaholics</h1>
        </div>
        {/* Menu Items */}
        <div className="hidden md:flex space-x-6">
          <a href="#">Pricing</a>
          <a href="#">Product</a>
          <a href="#">About us</a>
          <Link to="/login">Login</Link>
          <Link to="/register">Register</Link>
        </div>
        {/* Button */}
        <Link
          to="/login"
          className="hidden  p-3 px-6 text-white bg-brightRed rounded-full baseline hover:bg-brightRedLight md:block"
        >
          Get Started
        </Link>
        {/* Hamburger Icon */}
        <button
          className={`${isHamburgerBtn} block hamburger md:hidden focus:outline-none`}
          onClick={toggleHamburgerMenu}
        >
          <span className="hamburger-top"></span>
          <span className="hamburger-middle"></span>
          <span className="hamburger-bottom"></span>
        </button>
      </div>
      {/* mobile menu */}
      <div className="md:hidden">
        <div
          className={`${isMobileMenu} absolute flex flex-col items-center self-end  py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md`}
        >
          <a href="#">Pricing</a>
          <a href="#">Product</a>
          <a href="#">About us</a>
          <Link to="/login">Login</Link>
          <Link to="/register">Register</Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
