import React, { useState, useEffect } from "react";
import useRevision from "./../../hooks/useRevision";
import useAuth from "./../../hooks/useAuth";
// import useRegExam from "./../../hooks/useRegExam";
import useRegRevision from "./../../hooks/useRegRevision";

import ExamControlButton from "../reusableComponents/ExamControlButton";
import { useNavigate } from "react-router-dom";
// import useExamAns from "../../hooks/useExamAns";
import useRevisionAns from "../../hooks/useRevisionAns";

const RevisionsList = () => {
  const { user } = useAuth();
  const {
    revisionAnswerSheets,
    getAllRevisionAnswerSheets,
    deleteStudentAnsheet,
  } = useRevisionAns();
  const { registerRevisions, getAllRegRevisions, addRegisterRevision } =
    useRegRevision();
  const { revisions, getRevisions } = useRevision();

  const [isDisabled, setIsDisabled] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getRevisions();
    getAllRegRevisions();
    getAllRevisionAnswerSheets(user._id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAllRegRevisions();

    // eslint-disable-next-line
  }, [isDisabled]);

  // console.log(registerExams);

  const handleOnRegisterForRevision = (examId) => {
    // console.log(examId);
    addRegisterRevision({
      userId: user._id,
      examId,
    });
    setIsDisabled((prev) => !prev);
  };

  const handleGoStartRevision = (exam_id) => {
    navigate(`/students/revisions/${exam_id}`, { replace: true });
  };

  const handleDeleteRevisionAnsSheetAndRetry = (ansSheetId, exam_id) => {
    deleteStudentAnsheet(ansSheetId[0]?._id);
    navigate(`/students/revisions/${exam_id}`, { replace: true });
  };

  const renderButton = (eDate, eTime, eDuration, eId, uId) => {
    const date = new Date(eDate).toISOString().split("T")[0];
    const now = new Date();
    const start =
      new Date(`${date}T${eTime}:00.000Z`).getTime() - 2 * 60 * 60 * 1000; // add 2 hrs for Zambian time
    const finish = start + eDuration * 60 * 1000; // adding exam duration time

    const hasdWritten =
      revisionAnswerSheets?.findIndex((ansSheet) => {
        return ansSheet.examId === eId && ansSheet.userId?._id === uId;
      }) !== -1
        ? true
        : false;

    const answerSheetId = revisionAnswerSheets?.filter(
      (answerSheet) =>
        answerSheet.examId === eId && answerSheet.userId?._id === uId
    );
    // console.log(answerSheetId[0]?._id);

    const isRegisted =
      registerRevisions?.findIndex((regExam) => {
        return regExam.examId?._id === eId && regExam.userId?._id === uId;
      }) !== -1
        ? true
        : false;

    // console.log(isRegisted);
    // console.log(registerRevisions);
    // console.log(revisions);

    if (revisionAnswerSheets && revisionAnswerSheets !== [] && hasdWritten) {
      // already taken this exam
      return (
        <ExamControlButton
          text={"Retry"}
          bgColor={"bg-brightRedSupLight"}
          textColor={"text-gray-800"}
          onClick={() =>
            handleDeleteRevisionAnsSheetAndRetry(answerSheetId, eId)
          }
          // disabled={true}
        />
      );
    } else if (isRegisted && start <= now && now <= finish) {
      // registered and its time to take exam

      return (
        <ExamControlButton
          text={"Start"}
          bgColor={"bg-green-800"}
          onClick={() => handleGoStartRevision(eId)}
        />
      );
    } else if (finish < now) {
      // exam already past
      return (
        <ExamControlButton
          text={"Past"}
          bgColor={"bg-gray-200"}
          textColor={"text-gray-800"}
          onClick={() => {}}
          disabled={true}
        />
      );
    } else if (isRegisted && start > now) {
      // not yet exam time and not yet time for exam
      return (
        <ExamControlButton
          text={"Awaiting"}
          bgColor={"bg-gray-200"}
          textColor={"text-gray-800"}
          onClick={() => {}}
          disabled={true}
        />
      );
    }

    return (
      <ExamControlButton
        text={"Register"}
        onClick={() => handleOnRegisterForRevision(eId)}
        disabled={isDisabled}
      />
    );
  };

  return (
    <div className="border rounded-3xl  bg-veryLightGray shadow-md overflow-hidden">
      <div className="bg-brightRedSupLight">
        <div className=" py-4 px-6 text-2xl md:text-3xl text-slate-800 font-bold mb-1">
          <h1>Available Revision Papers</h1>
        </div>
      </div>

      <div className="overflow-auto">
        <table className="w-full">
          <thead className="bg-gray-50 border-b-2 border-gray-200 ">
            <tr>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">
                Title
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Course Code: Course
              </th>
              <th className="  w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Programme
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Department
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                School
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                University
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Date
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Time
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {revisions !== [] && Array.isArray(revisions)
              ? revisions?.map((exam, index) => {
                  // Course Code: Course
                  const courseCode = exam.courseCode + ": " + exam.course;

                  // date
                  const date = new Date(exam.date).toDateString();

                  // is index even
                  const isEven = index % 2 === 0;

                  return (
                    <tr
                      key={exam._id}
                      className={`${isEven ? "bg-white" : "bg-gray-50"}`}
                    >
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {exam.title}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {courseCode}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {exam.programme}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {exam.department}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {exam.school}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {exam.university}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {date}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {exam.time}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {renderButton(
                          exam.date,
                          exam.time,
                          exam.duration,
                          exam._id,
                          user._id
                        )}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>

      <div className="hidden px-6 py-2 mb-2">Actions</div>
    </div>
  );
};

export default RevisionsList;
