import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import useExam from "../../hooks/useExam";

import QuestionList from "./QuestionList";

const EditExam = ({ exam }) => {
  const { user } = useAuth();
  const { editExam, newExamQuestions, addQuestionsToEditExam } = useExam();
  const { setAlert } = useAlert();

  const examId = exam?._id;

  const navigate = useNavigate();

  useEffect(() => {
    let questionsIds = [];

    exam &&
      exam.questions.map((question) => {
        return questionsIds.push(question._id);
      });

    addQuestionsToEditExam(questionsIds);
    // console.log(newExamQuestions);
    return () => {};
  }, [exam]);

  useEffect(() => {
    const setDatePickerDate = (examDate) => {
      // console.log(examDate);
      var date = new Date(examDate);

      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();

      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;

      var inputDate = year + "-" + month + "-" + day;

      // console.log(inputDate);

      return inputDate;
    };

    if (exam) {
      setNewExam({
        ...newExam,
        title: exam.title,
        description: exam.description,
        courseCode: exam.courseCode,
        course: exam.course,
        programme: exam.programme,
        department: exam.department,
        school: exam.school,
        university: exam.university,
        topic: exam.topic,
        duration: exam.duration,
        date: setDatePickerDate(exam.date), // datepicker wants "yyyy-MM-dd" "2022-10-26"
        time: exam.time,
        createdBy: exam.createdBy,
      });
    } else {
    }
  }, [exam]);

  // const setDatePickerDate = (examDate) => {
  //   console.log(examDate);
  //   var date = new Date(examDate);

  //   var day = date.getDate();
  //   var month = date.getMonth() + 1;
  //   var year = date.getFullYear();

  //   if (month < 10) month = "0" + month;
  //   if (day < 10) day = "0" + day;

  //   var inputDate = day + "/" + month + "/" + year;

  //   console.log(inputDate);

  //   return inputDate;
  // };

  const [newExam, setNewExam] = useState({
    title: "",
    description: "",
    questions: [],
    courseCode: "",
    course: "",
    programme: "",
    department: "",
    school: "",
    university: "",
    topic: "",
    duration: 1,
    date: "",
    time: "",
    createdBy: "",
  });

  const {
    title,
    description,
    questions,
    courseCode,
    course,
    programme,
    department,
    school,
    university,
    topic,
    duration,
    date,
    time,
  } = newExam;

  const onChange = (e) => {
    setNewExam({ ...newExam, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      title === "" ||
      description === "" ||
      questions === [] ||
      courseCode === "" ||
      course === "" ||
      programme === "" ||
      department === "" ||
      school === "" ||
      university === "" ||
      date === "" ||
      time === "" ||
      duration === "" ||
      topic === ""
    ) {
      setAlert("Please enter all fields", "danger");
    } else {
      const exam = {
        title,
        description,
        questions: newExamQuestions,
        courseCode,
        course,
        programme,
        department,
        school,
        university,
        topic,
        date,
        time,
        duration,
        createdBy: user._id,
      };
      // console.log(examId);
      editExam(examId, exam);
      // console.log(exam);

      setTimeout(() => {
        navigate("/lecturers/examsreports", { replace: true });
      }, 1000);
    }
  };

  return (
    <div>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6"></div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200"></div>
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Exam Information
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Exam name must be unique. You must enter exam names not already
                there in the database.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form
              // action="#"
              // method="POST"
              onSubmit={onSubmit}
            >
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6">
                      <label
                        htmlFor="title"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        value={title}
                        onChange={onChange}
                        id="title"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6">
                      <label
                        htmlFor="description"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Description
                      </label>
                      <input
                        type="text"
                        name="description"
                        value={description}
                        onChange={onChange}
                        id="description"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="courseCode"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Course Code
                      </label>
                      <input
                        type="text"
                        name="courseCode"
                        value={courseCode}
                        onChange={onChange}
                        id="courseCode"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="course"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Course
                      </label>
                      <input
                        type="text"
                        name="course"
                        value={course}
                        onChange={onChange}
                        id="course"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="programme"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Programme
                      </label>
                      <input
                        type="text"
                        name="programme"
                        value={programme}
                        onChange={onChange}
                        id="programme"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="department"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Department
                      </label>
                      <input
                        type="text"
                        name="department"
                        value={department}
                        onChange={onChange}
                        id="department"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="school"
                        className="block text-sm font-medium text-gray-700"
                      >
                        School
                      </label>
                      <input
                        type="text"
                        name="school"
                        value={school}
                        onChange={onChange}
                        id="school"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="university"
                        className="block text-sm font-medium text-gray-700"
                      >
                        University
                      </label>
                      <input
                        type="text"
                        name="university"
                        value={university}
                        onChange={onChange}
                        id="university"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="topic"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Topic
                      </label>
                      <input
                        type="text"
                        name="topic"
                        value={topic}
                        onChange={onChange}
                        id="topic"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="date"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Date
                      </label>
                      <input
                        type="date"
                        name="date"
                        value={date}
                        onChange={onChange}
                        id="date"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="time"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Time
                      </label>
                      <input
                        type="time"
                        name="time"
                        value={time}
                        onChange={onChange}
                        id="time"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="duration"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Duration (in minutes)
                      </label>
                      <select
                        id="duration"
                        name="duration"
                        value={duration}
                        onChange={onChange}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-brightRedLight focus:border-brightRedLight sm:text-sm"
                      >
                        <option></option>
                        <option>30</option>
                        <option>60</option>
                        <option>90</option>
                        <option>120</option>
                        <option>150</option>
                        <option>180</option>
                        <option>210</option>
                        <option>240</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-brightRedLight hover:bg-brightRed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brightRedLight"
                  >
                    Edit Exam
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200"></div>
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Questions
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Here are set of questions to choose from. You can search
                questions by course, topic, or past exam names.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2 ">
            <div className="bg-veryLightGray border rounded-lg py-4 shadow-md">
              <h3 className="px-2 text-lg font-medium leading-6 text-gray-900">
                Number of questions picked:{" "}
                <span className="font-bold text-brightRed ">
                  {newExamQuestions?.length}
                </span>
              </h3>
            </div>

            <QuestionList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditExam;
