import React, { useEffect } from "react";
import useRegExam from "./../../hooks/useRegExam";
import ExamControlButton from "../reusableComponents/ExamControlButton";
const LecExamsList = ({ examId }) => {
  const { examRegistrations, getAllExamRegs, deRegisterStudent } = useRegExam();

  useEffect(() => {
    getAllExamRegs(examId);

    // eslint-disable-next-line
  }, []);

  const handleDeregiter = (regId) => {
    deRegisterStudent(regId);
  };

  return (
    <div className="border rounded-3xl  bg-veryLightGray shadow-md overflow-hidden">
      <div className="bg-brightRedSupLight">
        <div className=" py-4 px-6 text-2xl md:text-3xl text-slate-800 font-bold mb-1">
          <h1>Exam Registered Students</h1>
          <p className="text-md font-semibold">
            {examRegistrations && examRegistrations[0]?.examId.title}
          </p>
        </div>
      </div>

      <div className="overflow-auto">
        <table className="w-full">
          <thead className="bg-gray-50 border-b-2 border-gray-200 ">
            <tr>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">
                Name
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                ID
              </th>
              <th className="  w-24 p-3 text-sm font-semibold tracking-wide text-left">
                intake
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                programme
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                School
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                University
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Date of Registration
              </th>

              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {examRegistrations !== [] && Array.isArray(examRegistrations)
              ? examRegistrations?.map((examReg, index) => {
                  // date
                  const date = new Date(examReg.createdAt).toLocaleString();

                  // is index even
                  const isEven = index % 2 === 0;

                  return (
                    <tr
                      key={examReg._id}
                      className={`${isEven ? "bg-white" : "bg-gray-50"}`}
                    >
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {examReg.userId.name}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {examReg.userId.studentDetails?.computerNumber}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {examReg.userId.studentDetails?.intake}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {examReg.userId.studentDetails?.programme}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {examReg.userId.studentDetails?.school}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {examReg.userId.studentDetails?.university}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {date}
                      </td>

                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        <ExamControlButton
                          text="Deregister"
                          bgColor="bg-gray-200"
                          textColor="text-gray-800"
                          onClick={() => handleDeregiter(examReg._id)}
                        />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LecExamsList;
