import React, { useContext } from "react";

import WelcomeBanner from "../components/layout/WelcomeBanner";
import AuthContext from "../context/auth/AuthContext";

import ExamPaper from "../components/layout/ExamPaper";

const ExamPage = () => {
  const authContext = useContext(AuthContext);

  return (
    <>
      {/* Welcome banner */}
      <WelcomeBanner user={authContext.user} msg={"Exam in progress:"} />
      <ExamPaper user={authContext.user} />
    </>
  );
};

export default ExamPage;
