import React from "react";
import useExamAns from "../../hooks/useExamAns";
import ExamControlButton from "../reusableComponents/ExamControlButton";

function ExamDetails({
  exam,
  numQuestions,
  setCurrentQuestion,
  newExamAnswers,
  onClick,
  onAbandon,
}) {
  return (
    <div className="bg-veryLightGray p-4 sm:p-6 overflow-hidden rounded-lg shadow-md w-full mt-3 mb-4">
      {/* Background illustration */}
      <div
        className="absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden xl:block"
        aria-hidden="true"
      ></div>

      {/* Content */}
      <div className="relative ">
        <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mb-1">
          {exam?.title}
        </h1>
        <p>{exam?.description}</p>
      </div>
      <div className="flex flex-wrap border border-gray-300 rounded-md mt-2 p-1">
        {[...Array(numQuestions)].map((e, i) => {
          const answered = newExamAnswers?.find((quest) => {
            return quest.question.split(".")[0] === (i + 1).toString();
          })?.disabled;
          return (
            <div
              key={i}
              className={` px-2 py-1 m-1 text-gray-600 rounded-full md:py-1 ${
                answered && answered ? "bg-brightRedSupLight" : ""
              } 
              
              `}
            >
              <button
                onClick={() => {
                  // console.log(i + 1);
                  setCurrentQuestion(i);
                }}
              >
                {i + 1}
              </button>
            </div>
          );
        })}
      </div>
      <div className="flex justify-evenly pt-4">
        <ExamControlButton
          text={"Abort"}
          bgColor={" bg-brightRedLight hover:bg-brightRed"}
          onClick={onAbandon}
        />
        <ExamControlButton
          text={"Submit"}
          bgColor={" bg-brightRedLight hover:bg-brightRed"}
          onClick={onClick}
        />
      </div>
    </div>
  );
}

export default ExamDetails;
