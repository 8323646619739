import React, { useState } from "react";

import useAuth from "../../hooks/useAuth";
import useRevision from "../../hooks/useRevision";
// import { arrayRemove } from "../../utils/arrayRemove";
import ExamControlButton from "../reusableComponents/ExamControlButton";

const RevisionQuestion = ({ question }) => {
  const [showChoices, setShowChoices] = useState(false);
  const { user } = useAuth();
  const {
    newRevisionQuestions,
    addQuestionToRevision,
    removeQuestionFromRevision,
  } = useRevision();
  // console.log(user);

  let isAdmin = false;
  let isLecturer = false;

  if (user && user.role === "admin") {
    isAdmin = true;
  } else {
    isAdmin = false;
  }

  if (user && user.role === "lecturer") {
    isLecturer = true;
  } else {
    isLecturer = false;
  }

  const options = Object.keys(question.choices);

  const handleAddQuetion = (questionId) => {
    addQuestionToRevision(questionId);
    // console.log(newExamQuestions);
  };
  const handleRemoveQuetion = (questionId) => {
    removeQuestionFromRevision(questionId);
    // console.log(newExamQuestions);
  };
  const handleShowChoices = () => {
    setShowChoices(!showChoices);
  };

  return (
    <div className="accordion">
      <div>
        <label htmlFor={question._id} className="accordion__label">
          <li
            className={`${
              newRevisionQuestions.includes(question._id)
                ? "bg-brightRedSupLight"
                : "bg-veryLightGray"
            } p-2 rounded-lg shadow-md w-full mt-3`}
          >
            {question.question}{" "}
            <div className={`px-4 ${showChoices ? "" : "hidden"}`}>
              {options.map((option, index) => (
                <label
                  key={index}
                  htmlFor={option}
                  className="block border border-gray-300 rounded-md mt-2 p-1"
                >
                  <span className="accordion__span">
                    {option.toUpperCase()}. {question?.choices[option]}
                  </span>
                </label>
              ))}
            </div>
            {(isAdmin || isLecturer) && (
              <div className="flex flex-row space-x-2 mt-2">
                <ExamControlButton
                  onClick={() => handleAddQuetion(question._id)}
                  text={"Add"}
                  bgColor={"bg-brightRedSupLight"}
                  textColor={"text-brightRed"}
                />
                <ExamControlButton
                  onClick={() => handleRemoveQuetion(question._id)}
                  text={"Remove"}
                  bgColor={"bg-brightRedSupLight"}
                  textColor={"text-brightRed"}
                />
                <ExamControlButton
                  onClick={handleShowChoices}
                  text={"Show/Hide Choices"}
                  bgColor={"bg-brightRedSupLight"}
                  textColor={"text-brightRed"}
                />
              </div>
            )}
          </li>
        </label>
        <div className="accordion__content"></div>
      </div>
    </div>
  );
};

export default RevisionQuestion;
