import React, { useState } from "react";
import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import useQuestion from "../../hooks/useQuestion";

const AddQuestion = () => {
  const { user } = useAuth();
  const { addQuestion } = useQuestion();
  const { setAlert } = useAlert();

  const [newChoices, setNewChoices] = useState({
    a: "",
    b: "",
    c: "",
    d: "",
  });

  const { a, b, c, d } = newChoices;

  const [newQuestion, setNewQuestion] = useState({
    question: "",
    choices: {},
    answer: "",
    explanation: "",
    difficulty: "",
    typeOfQuestion: "",
    course: "",
    topic: "",
    createdBy: "",
  });

  const {
    question,
    choices,
    answer,
    explanation,
    difficulty,
    typeOfQuestion,
    course,
    topic,
  } = newQuestion;

  const onChange = (e) => {
    setNewChoices({ ...newChoices, [e.target.name]: e.target.value });
    setNewQuestion({ ...newQuestion, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      question === "" ||
      choices === {} ||
      answer === "" ||
      explanation === "" ||
      difficulty === "" ||
      typeOfQuestion === "" ||
      course === "" ||
      topic === ""
    ) {
      // console.log("question not submitted");
      // console.log(
      //   `question:${question} answer:${answer} explanation:${explanation} difficulty:${difficulty} typeOfQuestion:${typeOfQuestion} course:${course} topic:${topic} createdBy: ${user._id}  choices: { a: ${newChoices.a} b:${newChoices.b} c:${newChoices.c} d:${newChoices.d} }`
      // );
      setAlert("Please enter all fields", "danger");
    } else {
      addQuestion({
        question,
        answer,
        explanation,
        difficulty,
        typeOfQuestion,
        course,
        topic,
        createdBy: user._id,
        choices: {
          a: newChoices.a,
          b: newChoices.b,
          c: newChoices.c,
          d: newChoices.d,
        },
      });

      // clear question
      setNewQuestion({
        question: "",
        choices: {},
        answer: "",
        explanation: "",
        difficulty: "",
        typeOfQuestion: "",
        course: "",
        topic: "",
        createdBy: "",
      });

      // console.log("question submitted");
      // clear options
      setNewChoices({
        a: "",
        b: "",
        c: "",
        d: "",
      });
    }
  };

  return (
    <div>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          {/* <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Profile
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                This information will be displayed publicly so be careful what
                you share.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="company-website"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {" "}
                        Website{" "}
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                          {" "}
                          http://{" "}
                        </span>
                        <input
                          type="text"
                          name="company-website"
                          id="company-website"
                          className="focus:ring-brightRedLight focus:border-brightRedLight flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                          placeholder="www.example.com"
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="about"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {" "}
                      About{" "}
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="about"
                        name="about"
                        rows="3"
                        className="shadow-sm focus:ring-brightRedLight focus:border-brightRedLight mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                        placeholder="you@example.com"
                      ></textarea>
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      Brief description for your profile. URLs are hyperlinked.
                    </p>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      {" "}
                      Photo{" "}
                    </label>
                    <div className="mt-1 flex items-center">
                      <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                        <svg
                          className="h-full w-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                      <button
                        type="button"
                        className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brightRedLight"
                      >
                        Change
                      </button>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      {" "}
                      Cover photo{" "}
                    </label>
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-brightRedLight focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-brightRedLight"
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload"
                              name="file-upload"
                              type="file"
                              className="sr-only"
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">
                          PNG, JPG, GIF up to 10MB
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brightRedLight"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div> */}
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200"></div>
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Question Information
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Questions must be unique. You must enter question not already
                there in the database.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form
              // action="#"
              // method="POST"
              onSubmit={onSubmit}
            >
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="mb-5">
                    <label
                      htmlFor="question"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {" "}
                      New Question{" "}
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="question"
                        name="question"
                        value={question}
                        onChange={onChange}
                        rows="3"
                        className="shadow-sm focus:ring-brightRedLight focus:border-brightRedLight mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                        placeholder="Enter your question here..."
                      ></textarea>
                    </div>
                  </div>
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="option-a"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Option A
                      </label>
                      <input
                        type="text"
                        name="a"
                        value={a}
                        onChange={onChange}
                        id="option-a"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="option-b"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Option B
                      </label>
                      <input
                        type="text"
                        name="b"
                        value={b}
                        onChange={onChange}
                        id="option-b"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="option-c"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Option C
                      </label>
                      <input
                        type="text"
                        name="c"
                        value={c}
                        onChange={onChange}
                        id="option-c"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="option-d"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Option D
                      </label>
                      <input
                        type="text"
                        name="d"
                        value={d}
                        onChange={onChange}
                        id="option-d"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    {/* <div className="col-span-6 sm:col-span-4">
                      <label
                        htmlFor="email-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Currect Answer
                      </label>
                      <input
                        type="text"
                        name="answer"
                        id="answer"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div> */}

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="answer"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Currect Answer
                      </label>
                      <select
                        id="answer"
                        name="answer"
                        value={answer}
                        onChange={onChange}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-brightRedLight focus:border-brightRedLight sm:text-sm"
                      >
                        <option></option>
                        <option>a</option>
                        <option>b</option>
                        <option>c</option>
                        <option>d</option>
                      </select>
                    </div>

                    <div className="col-span-6">
                      <label
                        htmlFor="explanation"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Explanation
                      </label>
                      <input
                        type="text"
                        name="explanation"
                        value={explanation}
                        onChange={onChange}
                        id="explanation"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="difficulty"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Difficulty
                      </label>
                      <select
                        id="difficulty"
                        name="difficulty"
                        value={difficulty}
                        onChange={onChange}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-brightRedLight focus:border-brightRedLight sm:text-sm"
                      >
                        <option></option>
                        <option>easy</option>
                        <option>intermediate</option>
                        <option>professional</option>
                      </select>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="typeOfQuestion"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Type of Question
                      </label>
                      <select
                        id="typeOfQuestion"
                        name="typeOfQuestion"
                        value={typeOfQuestion}
                        onChange={onChange}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-brightRedLight focus:border-brightRedLight sm:text-sm"
                      >
                        <option></option>
                        <option>A1</option>
                        <option>A2</option>
                        <option>A3</option>
                      </select>
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="course"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Course
                      </label>
                      <input
                        type="text"
                        name="course"
                        value={course}
                        onChange={onChange}
                        id="course"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="topic"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Topic
                      </label>
                      <input
                        type="text"
                        name="topic"
                        value={topic}
                        onChange={onChange}
                        id="topic"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    {/* <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="region"
                        className="block text-sm font-medium text-gray-700"
                      >
                        State / Province
                      </label>
                      <input
                        type="text"
                        name="region"
                        id="region"
                        autocomplete="address-level1"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div> */}

                    {/* <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="postal-code"
                        className="block text-sm font-medium text-gray-700"
                      >
                        ZIP / Postal code
                      </label>
                      <input
                        type="text"
                        name="postal-code"
                        id="postal-code"
                        autocomplete="postal-code"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div> */}
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-brightRedLight hover:bg-brightRed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brightRedLight"
                  >
                    Add Question
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200"></div>
        </div>
      </div>

      {/* <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Notifications
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Decide which communications you'd like to receive and how.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <fieldset>
                    <legend className="sr-only">By Email</legend>
                    <div
                      className="text-base font-medium text-gray-900"
                      aria-hidden="true"
                    >
                      By Email
                    </div>
                    <div className="mt-4 space-y-4">
                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="comments"
                            name="comments"
                            type="checkbox"
                            className="focus:ring-brightRedLight h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="comments"
                            className="font-medium text-gray-700"
                          >
                            Comments
                          </label>
                          <p className="text-gray-500">
                            Get notified when someones posts a comment on a
                            posting.
                          </p>
                        </div>
                      </div>
                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="candidates"
                            name="candidates"
                            type="checkbox"
                            className="focus:ring-brightRedLight h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="candidates"
                            className="font-medium text-gray-700"
                          >
                            Candidates
                          </label>
                          <p className="text-gray-500">
                            Get notified when a candidate applies for a job.
                          </p>
                        </div>
                      </div>
                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="offers"
                            name="offers"
                            type="checkbox"
                            className="focus:ring-brightRedLight h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label for="offers" className="font-medium text-gray-700">
                            Offers
                          </label>
                          <p className="text-gray-500">
                            Get notified when a candidate accepts or rejects an
                            offer.
                          </p>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset>
                    <legend className="contents text-base font-medium text-gray-900">
                      Push Notifications
                    </legend>
                    <p className="text-sm text-gray-500">
                      These are delivered via SMS to your mobile phone.
                    </p>
                    <div className="mt-4 space-y-4">
                      <div className="flex items-center">
                        <input
                          id="push-everything"
                          name="push-notifications"
                          type="radio"
                          className="focus:ring-brightRedLight h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label
                          for="push-everything"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          {" "}
                          Everything{" "}
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="push-email"
                          name="push-notifications"
                          type="radio"
                          className="focus:ring-brightRedLight h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label
                          for="push-email"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          {" "}
                          Same as email{" "}
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="push-nothing"
                          name="push-notifications"
                          type="radio"
                          className="focus:ring-brightRedLight h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label
                          for="push-nothing"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          {" "}
                          No push notifications{" "}
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brightRedLight"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AddQuestion;
