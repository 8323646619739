import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import useRevision from "../../hooks/useRevision";

import RevisionQuestionList from "./RevisionQuestionList";

const CreateRevision = () => {
  const { user } = useAuth();
  const { addRevision, newRevisionQuestions } = useRevision();
  const { setAlert } = useAlert();

  let notApprovedWarning;
  if (user.isApproved !== true) {
    notApprovedWarning = (
      <span className="text-red-600 font-bold ">
        💥 💥 💥 You are not yet approved! To see more from the questions bank,
        contact admin to get approved 💥 💥 💥
      </span>
    );
  }

  const navigate = useNavigate();

  const [newExam, setNewExam] = useState({
    title: "",
    description: "",
    questions: [],
    courseCode: "",
    course: "",
    programme: "",
    department: "",
    school: "",
    university: "",
    topic: "",
    duration: 1,
    price: 1,
    date: "",
    time: "",
    createdBy: "",
  });

  const {
    title,
    description,
    questions,
    courseCode,
    course,
    programme,
    department,
    school,
    university,
    topic,
    duration,
    price,
    date,
    time,
  } = newExam;

  const onChange = (e) => {
    setNewExam({ ...newExam, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      title === "" ||
      description === "" ||
      questions === [] ||
      courseCode === "" ||
      course === "" ||
      programme === "" ||
      department === "" ||
      school === "" ||
      university === "" ||
      date === "" ||
      time === "" ||
      duration === "" ||
      price === "" ||
      topic === ""
    ) {
      setAlert("Please enter all fields", "danger");
    } else {
      const exam = {
        title,
        description,
        questions: newRevisionQuestions,
        courseCode,
        course,
        programme,
        department,
        school,
        university,
        topic,
        date,
        time,
        duration,
        price,
        createdBy: user._id,
      };
      addRevision(exam);
      // console.log(exam);

      setTimeout(() => {
        navigate("/students/exams", { replace: true });
      }, 1000);
    }
  };

  return (
    <div>
      {notApprovedWarning}
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6"></div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200"></div>
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Revision Test Information
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Revision name must be unique. You must enter Revision names not
                already there in the database.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form
              // action="#"
              // method="POST"
              onSubmit={onSubmit}
            >
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6">
                      <label
                        htmlFor="title"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        value={title}
                        onChange={onChange}
                        id="title"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6">
                      <label
                        htmlFor="description"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Description
                      </label>
                      <input
                        type="text"
                        name="description"
                        value={description}
                        onChange={onChange}
                        id="description"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="courseCode"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Course Code
                      </label>
                      <input
                        type="text"
                        name="courseCode"
                        value={courseCode}
                        onChange={onChange}
                        id="courseCode"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="course"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Course
                      </label>
                      <input
                        type="text"
                        name="course"
                        value={course}
                        onChange={onChange}
                        id="course"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="programme"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Programme
                      </label>
                      <input
                        type="text"
                        name="programme"
                        value={programme}
                        onChange={onChange}
                        id="programme"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="department"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Department
                      </label>
                      <input
                        type="text"
                        name="department"
                        value={department}
                        onChange={onChange}
                        id="department"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="school"
                        className="block text-sm font-medium text-gray-700"
                      >
                        School
                      </label>
                      <input
                        type="text"
                        name="school"
                        value={school}
                        onChange={onChange}
                        id="school"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="university"
                        className="block text-sm font-medium text-gray-700"
                      >
                        University
                      </label>
                      <input
                        type="text"
                        name="university"
                        value={university}
                        onChange={onChange}
                        id="university"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="topic"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Topic
                      </label>
                      <input
                        type="text"
                        name="topic"
                        value={topic}
                        onChange={onChange}
                        id="topic"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="date"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Date
                      </label>
                      <input
                        type="date"
                        name="date"
                        value={date}
                        onChange={onChange}
                        id="date"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="time"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Time
                      </label>
                      <input
                        type="time"
                        name="time"
                        value={time}
                        onChange={onChange}
                        id="time"
                        className="mt-1 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="duration"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Duration (in minutes)
                      </label>
                      <select
                        id="duration"
                        name="duration"
                        value={duration}
                        onChange={onChange}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-brightRedLight focus:border-brightRedLight sm:text-sm"
                      >
                        <option></option>
                        <option>5</option>
                        <option>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>60</option>
                        <option>90</option>
                        <option>120</option>
                        <option>150</option>
                        <option>180</option>
                        <option>210</option>
                        <option>240</option>
                      </select>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="price"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Price (in Kwacha)
                      </label>
                      <select
                        id="price"
                        name="price"
                        value={price}
                        onChange={onChange}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-brightRedLight focus:border-brightRedLight sm:text-sm"
                      >
                        <option></option>
                        <option>0</option>
                        <option>0.99</option>
                        <option>1.99</option>
                        <option>4.99</option>
                        <option>99.99</option>
                        <option>14.99</option>
                        <option>19.99</option>
                        <option>29.99</option>
                        <option>39.99</option>
                        <option>49.99</option>
                        <option>69.99</option>
                        <option>89.99</option>
                        <option>99.99</option>
                        <option>129.99</option>
                        <option>149.99</option>
                        <option>199.99</option>
                        <option>249.99</option>
                        <option>299.99</option>
                        <option>349.99</option>
                        <option>399.99</option>
                        <option>499.99</option>
                        <option>599.99</option>
                        <option>699.99</option>
                        <option>799.99</option>
                        <option>899.99</option>
                        <option>999.99</option>
                        <option>1299.99</option>
                        <option>1599.99</option>
                        <option>1999.99</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-brightRedLight hover:bg-brightRed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brightRedLight"
                  >
                    Create Exam
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200"></div>
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Questions
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Here are set of questions to choose from. You can search
                questions by course, topic, or past exam names.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2 ">
            <div className="bg-veryLightGray border rounded-lg py-4 shadow-md">
              <h3 className="px-2 text-lg font-medium leading-6 text-gray-900">
                Number of questions picked:{" "}
                <span className="font-bold text-brightRed ">
                  {newRevisionQuestions.length}
                </span>
              </h3>
            </div>

            <RevisionQuestionList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateRevision;
