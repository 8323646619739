import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import useExamAns from "./../hooks/useExamAns";
import { simplifiedMilliseconds } from "../utils/simplifyMilliseconds";
import { useParams } from "react-router-dom";
import ExamControlButton from "../components/reusableComponents/ExamControlButton";

const LecExamResultsPage = () => {
  const { examAnswerSheets, getAllExamResults, deleteStudentAnsheet } =
    useExamAns();
  const params = useParams();

  const [searchKeys, setSearchKeys] = useState(["name", "ID"]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    getAllExamResults(params.id);
    // eslint-disable-next-line
  }, []);

  // Search function
  const search = (entries) => {
    return (
      entries &&
      entries.filter((entry) =>
        searchKeys.some((key) => {
          if (key === "grade")
            return (
              entry[key]
                ?.split(",")[0]
                .toString()
                .toLowerCase()
                .indexOf(query.toLowerCase()) > -1
            );
          return (
            entry[key]?.toString().toLowerCase().indexOf(query.toLowerCase()) >
            -1
          );
        }),
      )
    );
  };

  const handleDelete = (answerSheetId) => {
    deleteStudentAnsheet(answerSheetId);
  };

  const keys =
    examAnswerSheets && examAnswerSheets[0] && Object.keys(examAnswerSheets[0]);

  return (
    <div className="border rounded-3xl  bg-veryLightGray shadow-md overflow-hidden">
      <div className="bg-brightRedSupLight">
        <div className=" py-4 px-6 text-2xl md:text-3xl text-slate-800 font-bold mb-1">
          <h1>Results</h1>
          <p className="text-md font-semibold">
            {examAnswerSheets && examAnswerSheets[0]?.title}
          </p>
          <CSVLink
            data={examAnswerSheets}
            headers={keys}
            filename={`${examAnswerSheets[0]?.title}-results.csv`}
          >
            <button>Export to CSV</button>
          </CSVLink>
        </div>
      </div>
      <div className="bg-veryLightGray   p-4 mt-3 shadow-md">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Search{" "}
          {examAnswerSheets !== [] && Array.isArray(examAnswerSheets) ? (
            <span className="text-brightRedLight">
              {search(examAnswerSheets).length}
            </span>
          ) : null}
          {examAnswerSheets !== [] && Array.isArray(examAnswerSheets) ? (
            <span className="text-sm font-small leading-6 text-gray-600">
              {search(examAnswerSheets).length === 1
                ? " result found"
                : " results found"}
            </span>
          ) : null}
        </h3>
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className="my-2 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />

        {keys &&
          // eslint-disable-next-line
          keys.map((key) => {
            if (
              key !== "answers" &&
              key !== "_id" &&
              key !== "id" &&
              key !== "examId" &&
              key !== "userId" &&
              // key !== "grade" &&
              key !== "unattempted" &&
              key !== "startedAt" &&
              key !== "finishedAt" &&
              key !== "numCorrectAnswers" &&
              key !== "numWrongAnswers" &&
              key !== "numQuestions" &&
              key !== "title" &&
              key !== "createdAt" &&
              // these are for student details
              key !== "studentDetails" &&
              key !== "programme" &&
              key !== "intake" &&
              key !== "school" &&
              key !== "university" &&
              key !== null
            )
              return (
                <label key={key} className="pr-6">
                  <input
                    className=""
                    type="checkbox"
                    checked={searchKeys.includes(key)}
                    onChange={(e) => {
                      const checked = searchKeys.includes(key);
                      setSearchKeys((prev) =>
                        checked
                          ? prev.filter((sk) => sk !== key)
                          : [...prev, key],
                      );
                    }}
                  />
                  <span className="px-2 capitalize">{key}</span>
                </label>
              );
          })}
      </div>

      <div className="overflow-auto">
        <table className="w-full">
          <thead className="bg-gray-50 border-b-2 border-gray-200 ">
            <tr>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">
                Name
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                ID
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Score
              </th>
              <th className="  w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Grade
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Time
              </th>

              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Correct
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Incorrect
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Skipped
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Date
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {examAnswerSheets !== [] && Array.isArray(examAnswerSheets)
              ? search(examAnswerSheets)?.map((ansSheet, index) => {
                  const finishedAt = new Date(ansSheet.finishedAt);
                  const startedAt = new Date(ansSheet.startedAt);

                  const timeTaken = simplifiedMilliseconds(
                    Math.abs(finishedAt - startedAt),
                  );

                  // covert date string to datetime
                  const finTime = `${finishedAt.toDateString()} ${finishedAt.toLocaleTimeString()}`;

                  // console.log(ansSheet);

                  // is index even
                  const isEven = index % 2 === 0;

                  return (
                    <tr
                      key={ansSheet._id}
                      className={`${isEven ? "bg-white" : "bg-gray-50"}`}
                    >
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {ansSheet.userId?.name}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {ansSheet.userId?.studentDetails.computerNumber}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {ansSheet.score}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        <span
                          className={`p-1.5 text-sm font-medium uppercase tracking-wider ${
                            ansSheet.score >= 50
                              ? " text-green-800 bg-green-200"
                              : " text-red-800 bg-red-200"
                          } rounded-lg bg-opacity-50`}
                        >
                          {ansSheet.grade.split(",")[0]}
                        </span>
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {timeTaken}
                      </td>

                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {ansSheet.numCorrectAnswers}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {ansSheet.numWrongAnswers}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {ansSheet.unattempted}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {finTime}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        <ExamControlButton
                          text="Delete"
                          bgColor="bg-gray-200"
                          textColor="text-gray-800"
                          onClick={() => handleDelete(ansSheet._id)}
                        />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LecExamResultsPage;
