import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto min-h-screen flex flex-col  py-12 px-6  lg:px-8 ">
      <p>
        This Privacy Policy governs the manner in which Examaholics Software Ltd
        collects, uses, maintains and discloses information collected from users
        (each, a "User") of the https://www.examaholics.com website ("Site").
        This privacy policy applies to the Site and all products and services
        offered by Examaholics Software Ltd.
      </p>

      <h3 className="hidden mt-6 mb-4 text-lg font-bold md:block">
        Personal identification information
      </h3>
      <p>
        We may collect personal identification information from Users in a
        variety of ways, including, but not limited to, when Users visit our
        site, register on the site, place an order, fill out a form, respond to
        a survey, and in connection with other activities, services, features or
        resources we make available on our Site. Users may be asked for, as
        appropriate, name, email address, mailing address, phone number. Users
        may, however, visit our Site anonymously. We will collect personal
        identification information from Users only if they voluntarily submit
        such information to us. Users can always refuse to supply personally
        identification information, except that it may prevent them from
        engaging in certain Site related activities.
      </p>

      <h3 className="hidden mt-6 mb-4 text-lg font-bold md:block">
        Non-personal identification information
      </h3>
      <p>
        We may collect non-personal identification information about Users
        whenever they interact with our Site. Non-personal identification
        information may include the browser name, the type of computer and
        technical information about Users means of connection to our Site, such
        as the operating system and the Internet service providers utilized and
        other similar information.
      </p>

      <h3 className="hidden mt-6 mb-4 text-lg font-bold md:block">
        Web browser cookies
      </h3>
      <p>
        Our Site may use "cookies" to enhance User experience. User's web
        browser places cookies on their hard drive for record-keeping purposes
        and sometimes to track information about them. User may choose to set
        their web browser to refuse cookies, or to alert you when cookies are
        being sent. If they do so, note that some parts of the Site may not
        function properly.
      </p>

      <h3 className="hidden mt-6 mb-4 text-lg font-bold md:block">
        How we use collected information
      </h3>
      <p>
        {" "}
        Examaholics Software Ltd may collect and use Users personal information
        for the following purposes:
      </p>
      <ul>
        <li>
          <i>To run and operate our Site</i>
          <br />
          We may need your information display content on the Site correctly.
        </li>
        <li>
          <i>To improve customer service</i>
          <br />
          Information you provide helps us respond to your customer service
          requests and support needs more efficiently.
        </li>
        <li>
          <i>To personalize user experience</i>
          <br />
          We may use information in the aggregate to understand how our Users as
          a group use the services and resources provided on our Site.
        </li>
        <li>
          <i>To improve our Site</i>
          <br />
          We may use feedback you provide to improve our products and services.
        </li>
        <li>
          <i>To process payments</i>
          <br />
          We may use the information Users provide about themselves when placing
          an order only to provide service to that order. We do not share this
          information with outside parties except to the extent necessary to
          provide the service.
        </li>
        <li>
          <i>To run a promotion, contest, survey or other Site feature</i>
          <br />
          To send Users information they agreed to receive about topics we think
          will be of interest to them.
        </li>
        <li>
          <i>To send periodic emails</i>
          <br />
          We may use the email address to send User information and updates
          pertaining to their order. It may also be used to respond to their
          inquiries, questions, and/or other requests.
        </li>
      </ul>

      <h3 className="hidden mt-6 mb-4 text-lg font-bold md:block">
        How we protect your information
      </h3>
      <p>
        We adopt appropriate data collection, storage and processing practices
        and security measures to protect against unauthorized access,
        alteration, disclosure or destruction of your personal information,
        username, password, transaction information and data stored on our Site.
      </p>

      <h3 className="hidden mt-6 mb-4 text-lg font-bold md:block">
        Sharing your personal information
      </h3>
      <p>
        We do not sell, trade, or rent Users personal identification information
        to others. We may share generic aggregated demographic information not
        linked to any personal identification information regarding visitors and
        users with our business partners, trusted affiliates and advertisers for
        the purposes outlined above. We may use third party service providers to
        help us operate our business and the Site or administer activities on
        our behalf, such as sending out newsletters or surveys. We may share
        your information with these third parties for those limited purposes
        provided that you have given us your permission.
      </p>

      <h3 className="hidden mt-6 mb-4 text-lg font-bold md:block">
        Electronic newsletters
      </h3>
      <p>
        If User decides to opt-in to our mailing list, they will receive emails
        that may include company news, updates, related product or service
        information, etc. If at any time the User would like to unsubscribe from
        receiving future emails, we include detailed unsubscribe instructions at
        the bottom of each email or User may contact us via our Site. We may use
        third party service providers to help us operate our business and the
        Site or administer activities on our behalf, such as sending out
        newsletters or surveys. We may share your information with these third
        parties for those limited purposes provided that you have given us your
        permission.
      </p>

      <h3 className="hidden mt-6 mb-4 text-lg font-bold md:block">
        Third party websites
      </h3>
      <p>
        Users may find advertising or other content on our Site that link to the
        sites and services of our partners, suppliers, advertisers, sponsors,
        licensors and other third parties. We do not control the content or
        links that appear on these sites and are not responsible for the
        practices employed by websites linked to or from our Site. In addition,
        these sites or services, including their content and links, may be
        constantly changing. These sites and services may have their own privacy
        policies and customer service policies. Browsing and interaction on any
        other website, including websites which have a link to our Site, is
        subject to that website's own terms and policies.
      </p>

      <h3 className="hidden mt-6 mb-4 text-lg font-bold md:block">
        Compliance with children's online privacy protection act
      </h3>
      <p>
        Protecting the privacy of the very young is especially important. For
        that reason, we never collect or maintain information at our Site from
        those we actually know are under 13, and no part of our website is
        structured to attract anyone under 13.
      </p>

      <h3 className="hidden mt-6 mb-4 text-lg font-bold md:block">
        Changes to this privacy policy
      </h3>
      <p>
        {" "}
        Examaholics Software Ltd has the discretion to update this privacy
        policy at any time. When we do, we will post a notification on the main
        page of our Site, revise the updated date at the bottom of this page and
        send you an email. We encourage Users to frequently check this page for
        any changes to stay informed about how we are helping to protect the
        personal information we collect. You acknowledge and agree that it is
        your responsibility to review this privacy policy periodically and
        become aware of modifications.
      </p>

      <h3 className="hidden mt-6 mb-4 text-lg font-bold md:block">
        Your acceptance of these terms
      </h3>
      <p>
        By using this Site, you signify your acceptance of this policy. If you
        do not agree to this policy, please do not use our Site. Your continued
        use of the Site following the posting of changes to this policy will be
        deemed your acceptance of those changes.
      </p>

      <h3 className="hidden mt-6 mb-4 text-lg font-bold md:block">
        Contacting us
      </h3>
      <p>
        If you have any questions about this Privacy Policy, the practices of
        this site, or your dealings with this site, please contact us.
      </p>

      <p>This document was last updated on October 23, 2022</p>
    </div>
  );
};

export default PrivacyPolicy;
