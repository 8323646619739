import { arrayRemove } from "../../utils/arrayRemove";

import {
  ADD_EXAM_SUCCESS,
  ADD_EXAM_FAIL,
  GET_EXAMS_SUCCESS,
  GET_EXAMS_FAIL,
  GET_EXAM_SUCCESS,
  GET_EXAM_FAIL,
  ADD_QUESTION_TO_EXAM,
  REMOVE_QUESTION_FROM_EXAM,
  LOGOUT,
  GET_LECTURER_EXAMS_SUCCESS,
  GET_LECTURER_EXAMS_FAIL,
  ADD_QUESTIONS_TO_EDIT_EXAM,
  EDIT_EXAM_SUCCESS,
} from "../types";

const ExamReducer = (state, action) => {
  switch (action.type) {
    case ADD_EXAM_SUCCESS:
    case GET_EXAMS_SUCCESS:
      return {
        ...state,
        // exams: [action.payload, ...state.exams],
        exams: action.payload,
      };
    case EDIT_EXAM_SUCCESS:
      return {
        ...state,
        lecturerExams: [action.payload, ...state.lecturerExams].find(
          (exam) => exam._id !== action.payload._id
        ),
        // exams: action.payload,
      };
    case GET_LECTURER_EXAMS_SUCCESS:
      return {
        ...state,
        lecturerExams: action.payload,
      };
    case GET_LECTURER_EXAMS_FAIL:
      return {
        ...state,
        lecturerExams: [],
      };
    case GET_EXAM_SUCCESS:
      return {
        ...state,
        exam: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        exams: null,
      };
    case ADD_QUESTION_TO_EXAM:
      return {
        ...state,
        newExamQuestions: [action.payload, ...state.newExamQuestions],
      };
    case ADD_QUESTIONS_TO_EDIT_EXAM:
      return {
        ...state,
        newExamQuestions: action.payload,
      };
    case REMOVE_QUESTION_FROM_EXAM:
      return {
        ...state,
        newExamQuestions: arrayRemove(state.newExamQuestions, action.payload),
      };
    case ADD_EXAM_FAIL:
    case GET_EXAMS_FAIL:
    case GET_EXAM_FAIL:
      return {
        ...state,
        exams: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default ExamReducer;
