import React, { useEffect } from "react";
import useExam from "./../../hooks/useExam";
import useAuth from "./../../hooks/useAuth";
import useRegExam from "./../../hooks/useRegExam";

import ExamControlButton from "../reusableComponents/ExamControlButton";
import { useNavigate } from "react-router-dom";

const LecExamsList = () => {
  const { user } = useAuth();
  const { getAllRegExams } = useRegExam();
  const { lecturerExams, getLecturerExams } = useExam();

  const navigate = useNavigate();

  useEffect(() => {
    getAllRegExams();
    getLecturerExams(user._id);
    // eslint-disable-next-line
  }, []);

  const handleGoToExamRegister = (exam_id) => {
    navigate(`/lecturers/exams/${exam_id}`, { replace: true });
  };
  const handleGoToExamResults = (exam_id) => {
    navigate(`/lecturers/examresults/${exam_id}`, { replace: true });
  };
  const handleGoToEditExam = (exam_id) => {
    navigate(`/lecturers/editexam/${exam_id}`, { replace: true });
  };

  return (
    <div className="border rounded-3xl  bg-veryLightGray shadow-md overflow-hidden">
      <div className="bg-brightRedSupLight">
        <div className=" py-4 px-6 text-2xl md:text-3xl text-slate-800 font-bold mb-1">
          <h1>My Upcoming Exams</h1>
        </div>
      </div>

      <div className="overflow-auto">
        <table className="w-full">
          <thead className="bg-gray-50 border-b-2 border-gray-200 ">
            <tr>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">
                Title
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Course Code: Course
              </th>
              <th className="  w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Programme
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Department
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                School
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                University
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Date
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Time
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {lecturerExams !== [] && Array.isArray(lecturerExams)
              ? lecturerExams?.map((exam, index) => {
                  // Course Code: Course
                  const courseCode = exam.courseCode + ": " + exam.course;

                  // date
                  const date = new Date(exam.date).toDateString();

                  // is index even
                  const isEven = index % 2 === 0;

                  // react export to csv
                  console.log(exam._id);

                  return (
                    <tr
                      key={exam._id}
                      className={`${isEven ? "bg-white" : "bg-gray-50"}`}
                    >
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {exam.title}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {courseCode}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {exam.programme}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {exam.department}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {exam.school}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {exam.university}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {date}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {exam.time}
                      </td>
                      <td className="flex flex-row space-x-1 p-3 whitespace-nowrap test-sm text-gray-700 ">
                        <ExamControlButton
                          text={"Registered Students"}
                          bgColor={"bg-brightRedLight"}
                          onClick={() => handleGoToExamRegister(exam._id)}
                        />
                        <ExamControlButton
                          text={"Results"}
                          bgColor={"bg-brightRedLight"}
                          onClick={() => handleGoToExamResults(exam._id)}
                        />

                        <ExamControlButton
                          text={"Edit"}
                          bgColor={"bg-brightRedLight"}
                          onClick={() => {
                            handleGoToEditExam(exam._id);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LecExamsList;
