// converts milliseconds of time to human readable
export function simplifiedMilliseconds(milliseconds) {
  const totalSeconds = parseInt(Math.floor(milliseconds / 1000));
  const totalMinutes = parseInt(Math.floor(totalSeconds / 60));
  const totalHours = parseInt(Math.floor(totalMinutes / 60));
  const days = parseInt(Math.floor(totalHours / 24));

  const seconds = parseInt(totalSeconds % 60);
  const minutes = parseInt(totalMinutes % 60);
  const hours = parseInt(totalHours % 24);

  let time = "1s";
  if (days > 0) {
    time = `${days}d:${hours}h:${minutes}m:${seconds}s`;
  } else if (hours > 0) {
    time = `${hours}h:${minutes}m:${seconds}s`;
  } else if (minutes > 0) {
    time = `${minutes}m:${seconds}s`;
  } else if (seconds > 0) {
    time = `${seconds}s`;
  }
  return time;
}
