import React, { useReducer } from "react";
import axios from "axios";

import RevisionContext from "./RevisionContext";
import RevisionReducer from "./RevisionReducer";

import {
  ADD_REVISION_SUCCESS,
  ADD_REVISION_FAIL,
  GET_REVISIONS_SUCCESS,
  GET_REVISIONS_FAIL,
  GET_REVISION_SUCCESS,
  GET_REVISION_FAIL,
  ADD_QUESTION_TO_REVISION,
  REMOVE_QUESTION_FROM_REVISION,
  GET_LECTURER_REVISIONS_SUCCESS,
  GET_LECTURER_REVISIONS_FAIL,
  ADD_QUESTIONS_TO_EDIT_REVISION,
  EDIT_REVISION_SUCCESS,
} from "../types";

const RevisionState = (props) => {
  const initialState = {
    revisions: [],
    lecturerRevisions: [],
    revision: null,
    newRevisionQuestions: [],
    error: null,
  };

  const [state, dispatch] = useReducer(RevisionReducer, initialState);

  // MongoDB Actions

  // add new question
  async function addRevision(revision) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // console.log("logging from state side");
    try {
      const res = await axios.post("/api/v1/revisions", revision, config);
      // console.log(res);

      dispatch({
        type: ADD_REVISION_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: ADD_REVISION_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // get all exams
  async function getRevisions() {
    // console.log("logging from state side");
    try {
      const res = await axios.get("/api/v1/revisions");
      // console.log(res.data.data.data);

      dispatch({
        type: GET_REVISIONS_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_REVISIONS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }
  // get all lecturer exams
  async function getLecturerRevisions(userId) {
    const searchParams = userId ? "?createdBy=" + userId : "";
    // console.log("logging from state side");
    try {
      const res = await axios.get(`/api/v1/revisions${searchParams}`);
      // console.log(res.data.data.data);

      dispatch({
        type: GET_LECTURER_REVISIONS_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_LECTURER_REVISIONS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // get one exam
  async function getRevision(examId) {
    // console.log("logging from state side");
    try {
      const res = await axios.get(`/api/v1/revisions/${examId}`);
      // console.log(res.data.data.data);

      dispatch({
        type: GET_REVISION_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_REVISION_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // edit exam
  async function editRevision(examId, exam) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // console.log("logging from state side");
    try {
      const res = await axios.patch(
        `/api/v1/revisions/${examId}`,
        exam,
        config
      );
      // console.log(res.data.data.data);

      dispatch({
        type: EDIT_REVISION_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_REVISION_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  function addQuestionsToEditRevision(questionsIds) {
    // console.log(questionsIds);
    dispatch({
      type: ADD_QUESTIONS_TO_EDIT_REVISION,
      payload: questionsIds,
    });
  }
  function addQuestionToRevision(questionId) {
    if (state.newRevisionQuestions.includes(questionId)) return;
    dispatch({
      type: ADD_QUESTION_TO_REVISION,
      payload: questionId,
    });
  }
  function removeQuestionFromRevision(questionId) {
    if (!state.newRevisionQuestions.includes(questionId)) return;
    dispatch({
      type: REMOVE_QUESTION_FROM_REVISION,
      payload: questionId,
    });
  }

  return (
    <RevisionContext.Provider
      value={{
        revisions: state.revisions,
        lecturerRevisions: state.lecturerRevisions,
        revision: state.revision,
        newRevisionQuestions: state.newRevisionQuestions,
        error: state.error,
        addRevision,
        getRevision,
        editRevision,
        getRevisions,
        addQuestionToRevision,
        addQuestionsToEditRevision,
        removeQuestionFromRevision,
        getLecturerRevisions,
      }}
    >
      {props.children}
    </RevisionContext.Provider>
  );
};

export default RevisionState;
