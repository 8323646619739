import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthState from "./context/auth/AuthState";
import AlertState from "./context/alert/AlertState";
import QuestionState from "./context/question/QuestionState";
import ExamState from "./context/exam/ExamState";
import RevisionState from "./context/revision/RevisionState";
import ExamAnsState from "./context/answer/ExamAnsState";
import RegExamState from "./context/registerexam/RegExamState";
import RevisionAnsState from "./context/revisionanswer/RevisionAnsState";
import RegRevisionState from "./context/registerrevision/RegRevisionState";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Router>
    <AuthState>
      <AlertState>
        <QuestionState>
          <ExamState>
            <RevisionState>
              <ExamAnsState>
                <RevisionAnsState>
                  <RegExamState>
                    <RegRevisionState>
                      <Routes>
                        <Route path="/*" element={<App />} />
                      </Routes>
                    </RegRevisionState>
                  </RegExamState>
                </RevisionAnsState>
              </ExamAnsState>
            </RevisionState>
          </ExamState>
        </QuestionState>
      </AlertState>
    </AuthState>
  </Router>
  // </React.StrictMode>
);
