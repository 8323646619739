import React, { useState, useEffect } from "react";
import useExam from "./../../hooks/useExam";
import useAuth from "./../../hooks/useAuth";
import useRegExam from "./../../hooks/useRegExam";

import ExamControlButton from "../reusableComponents/ExamControlButton";
import { useNavigate } from "react-router-dom";
import useExamAns from "../../hooks/useExamAns";

const ExamsList = () => {
  const { user } = useAuth();
  const { examAnswerSheets, getAllExamAnswerSheets } = useExamAns();
  const { registerExams, getAllRegExams, addRegisterExam } = useRegExam();
  const { exams, getExams } = useExam();

  const [isDisabled, setIsDisabled] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getExams();
    getAllRegExams();
    getAllExamAnswerSheets(user._id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAllRegExams();

    // eslint-disable-next-line
  }, [isDisabled]);

  // console.log(registerExams);

  const handleOnRegisterForExam = (examId) => {
    addRegisterExam({
      userId: user._id,
      examId,
    });
    setIsDisabled((prev) => !prev);
  };

  const handleGoStartExam = (exam_id) => {
    navigate(`/students/exams/${exam_id}`, { replace: true });
  };

  const renderButton = (eDate, eTime, eDuration, eId, uId) => {
    const date = new Date(eDate).toISOString().split("T")[0];
    const now = new Date();
    const start =
      new Date(`${date}T${eTime}:00.000Z`).getTime() - 2 * 60 * 60 * 1000; // add 2 hrs for Zambian time
    const finish = start + eDuration * 60 * 1000; // adding exam duration time

    const hasdWritten =
      examAnswerSheets?.findIndex((ansSheet) => {
        return ansSheet.examId === eId && ansSheet.userId?._id === uId;
      }) !== -1
        ? true
        : false;

    const isRegisted =
      registerExams?.findIndex((regExam) => {
        return regExam.examId?._id === eId && regExam.userId?._id === uId;
      }) !== -1
        ? true
        : false;

    if (examAnswerSheets && examAnswerSheets !== [] && hasdWritten) {
      // already taken this exam
      return (
        <ExamControlButton
          text={"Written"}
          bgColor={"bg-gray-200"}
          textColor={"text-gray-800"}
          onClick={() => {}}
          disabled={true}
        />
      );
    } else if (isRegisted && start <= now && now <= finish) {
      // registered and its time to take exam

      return (
        <ExamControlButton
          text={"Start"}
          bgColor={"bg-green-800"}
          onClick={() => handleGoStartExam(eId)}
        />
      );
    } else if (finish < now) {
      // exam already past
      return (
        <ExamControlButton
          text={"Past"}
          bgColor={"bg-gray-200"}
          textColor={"text-gray-800"}
          onClick={() => {}}
          disabled={true}
        />
      );
    } else if (isRegisted && start > now) {
      // not yet exam time and not yet time for exam
      return (
        <ExamControlButton
          text={"Awaiting"}
          bgColor={"bg-gray-200"}
          textColor={"text-gray-800"}
          onClick={() => {}}
          disabled={true}
        />
      );
    }

    return (
      <ExamControlButton
        text={"Register"}
        onClick={() => handleOnRegisterForExam(eId)}
        disabled={isDisabled}
      />
    );
  };

  return (
    <div className="border rounded-3xl  bg-veryLightGray shadow-md overflow-hidden">
      <div className="bg-brightRedSupLight">
        <div className=" py-4 px-6 text-2xl md:text-3xl text-slate-800 font-bold mb-1">
          <h1>Upcoming Exams</h1>
        </div>
      </div>

      <div className="overflow-auto">
        <table className="w-full">
          <thead className="bg-gray-50 border-b-2 border-gray-200 ">
            <tr>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">
                Title
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Course Code: Course
              </th>
              <th className="  w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Programme
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Department
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                School
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                University
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Date
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Time
              </th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {exams !== [] && Array.isArray(exams)
              ? exams?.map((exam, index) => {
                  // Course Code: Course
                  const courseCode = exam.courseCode + ": " + exam.course;

                  // date
                  const date = new Date(exam.date).toDateString();

                  // is index even
                  const isEven = index % 2 === 0;

                  return (
                    <tr
                      key={exam._id}
                      className={`${isEven ? "bg-white" : "bg-gray-50"}`}
                    >
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {exam.title}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {courseCode}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {exam.programme}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {exam.department}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {exam.school}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {exam.university}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {date}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {exam.time}
                      </td>
                      <td className="p-3 whitespace-nowrap test-sm text-gray-700 ">
                        {renderButton(
                          exam.date,
                          exam.time,
                          exam.duration,
                          exam._id,
                          user._id
                        )}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>

      <div className="hidden px-6 py-2 mb-2">Actions</div>
    </div>
  );
};

export default ExamsList;
