import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import ExamControlButton from "../reusableComponents/ExamControlButton";

const ResetPassword = (props) => {
  const { setAlert } = useAlert();
  const { resetPassword, error, clearErrors, isAuthenticated } = useAuth();

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }

    if (error === "Duplicate field value entered") {
      setAlert(error, "danger");
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error, isAuthenticated]);

  const [user, setUser] = useState({
    password: "",
    password2: "",
  });

  const { password, password2 } = user;

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (password === "") {
      setAlert("Please enter all fields", "danger");
    } else if (password !== password2) {
      setAlert("Passwords don't match", "danger");
    } else {
      resetPassword({ resetPasswordToken: params.token, password, password2 });
    }
  };

  return (
    <div className="min-h-screen flex flex-col  py-12 px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Reset password
        </h2>
        <form className="mt-6" onSubmit={onSubmit}>
          <div className="form-control mt-6">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={onChange}
              required
              minLength="6"
              placeholder="Enter password..."
            />
          </div>
          <div className="form-control mt-6">
            <label htmlFor="password2">Confirm password</label>
            <input
              type="password"
              name="password2"
              value={password2}
              onChange={onChange}
              required
              minLength="6"
              placeholder="Confirm password..."
            />
          </div>
          {/* <button className="btn mt-6">Reset password</button> */}
          <div className="mt-6 flex justify-center items-center">
            <ExamControlButton text={"Reset password"} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
