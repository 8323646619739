import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useAuth from "./../hooks/useAuth";
import setAuthToken from "../utils/setAuthToken";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

export const PersistLogin = () => {
  const { loading, loadUser } = useAuth();

  useEffect(() => {
    localStorage.token && loadUser();
  }, []);
  return <>{loading ? <p>Loading</p> : <Outlet />}</>;
};
