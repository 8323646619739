import React from "react";

import Footer from "../components/layout/Footer";
import Navbar from "../components/layout/Navbar";

import RegisterLecturer from "../components/RegisterLecturer";

const RegisterLecturerPage = () => {
  return (
    <div>
      <Navbar />
      <RegisterLecturer />
      <Footer />
    </div>
  );
};

export default RegisterLecturerPage;
