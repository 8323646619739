import React from "react";
import heroSvg from "./../../img/illustration-intro.svg";

const Hero = () => {
  return (
    <div>
      {/* Flex Container */}
      <div className="container flex flex-col-reverse md:flex-row  items-center px-6 mx-auto mt--10 space-y-0 md:space-y-0 ">
        {/* left item */}
        <div className="flex flex-col mb-32 space-y-12 md:w-1/2">
          <h1 className="max-w-md text-4xl font-bold text-center md:text-5xl md:text-left">
            Bring everyone together to write better exams
          </h1>
          <p className="max-w-sm text-center text-darkGrayishBlue md:text-left">
            Examaholics makes it simple for students as well as lecturers
            prepare and write exams while keeping the larger goals in view.
          </p>
          <div className="flex justify-center md:justify-start">
            <a
              href="#"
              className="  p-3 px-6 text-white bg-brightRed rounded-full baseline hover:bg-brightRedLight "
            >
              Get Started
            </a>
          </div>
        </div>
        {/* right item */}
        <div className="md:w-1/2">
          <img src={heroSvg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
