import React, { useState, useEffect } from "react";

const calcTimeLeft = (t) => {
  if (!t) return 0;

  const left = t - new Date().getTime();
  if (left < 0) return 0;

  return left;
};

const CountDownTimer = ({ endTime, toDo }) => {
  // console.log(endTime);
  const [end, setEndTime] = useState(endTime);
  const [timeLeft, setTimeLeft] = useState(() => calcTimeLeft(end));

  useEffect(() => {
    setTimeLeft(calcTimeLeft(end));

    const timer = setInterval(() => {
      const targetLeft = calcTimeLeft(end);
      setTimeLeft(targetLeft);

      if (targetLeft === 0) {
        // put todo here
        toDo();
        // clear timer
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [end]);

  const hours = Math.floor((timeLeft / 3600000) % 24);
  const minutes = Math.floor(timeLeft / 60000) % 60;
  const seconds = Math.floor(timeLeft / 1000) % 60;

  // console.log(`${hours}:${minutes}:${seconds}`);

  return <p>{`${hours}:${minutes}:${seconds}`}</p>;
};

export default CountDownTimer;
