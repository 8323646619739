import React from "react";

import WelcomeBanner from "../components/layout/WelcomeBanner";

import useAuth from "../hooks/useAuth";
import ExamsList from "../components/layout/ExamsList";
import RevisionsList from "../components/layout/RevisionsList";

const RevisionsListPage = () => {
  const { user } = useAuth();

  return (
    <>
      {/* Welcome banner */}
      <WelcomeBanner user={user} msg={"See listed Revisions here:"} />
      {/* <ExamLinks /> */}
      <RevisionsList />
    </>
  );
};

export default RevisionsListPage;
