import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ExamQuestion from "../components/layout/ExamQuestion";
import ReviewQuestion from "../components/layout/ReviewQuestion";
import ExamControlButton from "../components/reusableComponents/ExamControlButton";

import useRevisionAns from "../hooks/useRevisionAns";

const RevisionAnswers = () => {
  const { results, deleteStudentAnsheet } = useRevisionAns();
  // console.log(results);

  const [showChoices, setShowChoices] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const navigate = useNavigate();

  const questions = results?.answers;
  const numQuestions = questions?.length;

  // keeps track of the current question
  let key = questions && questions[currentQuestion]?._id;
  let question = questions && questions[currentQuestion];

  // handles next question button logic
  const handleNext = () => {
    if (currentQuestion + 1 < numQuestions) {
      setCurrentQuestion(currentQuestion + 1);

      key = questions[currentQuestion]._id;
      question = questions[currentQuestion];
    } else {
      // setShowSubmitExamModal(true);
      // console.log(showSubmitExamModal);
    }
  };

  // handles previous question button logic
  const handlePrev = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);

      key = questions[currentQuestion]._id;
      question = questions[currentQuestion];
    } else {
    }
  };

  // handles previous question button logic
  const handleExpandExplanation = () => {
    setShowChoices(!showChoices);
  };

  // handles previous question button logic
  const handleRetryRevision = () => {
    deleteStudentAnsheet(results?._id);

    navigate(`/students/revisions/${results?.examId}`, { replace: true });
  };

  return (
    <div className="mt-8 border rounded-3xl  bg-veryLightGray shadow-md overflow-hidden">
      <div className="bg-brightRedSupLight">
        <div className=" py-4 px-6 text-2xl md:text-3xl text-slate-800 font-bold mb-1">
          <h1>Review</h1>
        </div>
      </div>
      <div className="mb-6">
        <div>
          <div className="flex flex-wrap border border-gray-300 rounded-md m-4 p-1">
            {[...Array(numQuestions)].map((e, i) => {
              const answeredCorrectly = questions?.find((quest) => {
                return quest.question.split(".")[0] === (i + 1).toString();
              })?.correct;
              // const isCorrect = question && question.correct;
              return (
                <div
                  key={i}
                  className={` px-2 py-1 m-1 text-gray-600 rounded-full md:py-1 ${
                    answeredCorrectly ? "bg-green-200" : "bg-red-200"
                  } 
              
              `}
                >
                  <button
                    onClick={() => {
                      // console.log(i + 1);
                      setCurrentQuestion(i);
                    }}
                  >
                    {i + 1}
                  </button>
                </div>
              );
            })}
          </div>

          <div className="flex flex-row justify-between">
            <h3 className="ml-4 text-lg font-bold md:block">
              Question {currentQuestion + 1}
            </h3>
            <h3 className="ml-2 text-lg text-brightRed font-bold md:block"></h3>
          </div>

          <ul className="list">
            <div className="px-4">
              {question && (
                <ReviewQuestion
                  key={key}
                  question={question}
                  // onChange={onChange}
                  disabled={true}
                  selected={question.selected}
                  correct={question.correctAnswer}
                  choose={question.choosenAnswer}
                  // undo={handleUndo}
                />
              )}
            </div>
          </ul>
          <div className={`mt-6 px-10 ${showChoices ? "" : "hidden"}`}>
            {`Explanation: ${question && question.explanation}`}
          </div>
          <div className="flex justify-evenly mt-8">
            <ExamControlButton onClick={handlePrev} text={"Prev"} />
            <ExamControlButton onClick={handleNext} text={"Next"} />
            <ExamControlButton
              onClick={handleExpandExplanation}
              text={"Logos"}
            />
            <ExamControlButton onClick={handleRetryRevision} text={"Retry"} />
          </div>
          <div className=""></div>
        </div>
      </div>
    </div>
  );
};

export default RevisionAnswers;
