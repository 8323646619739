// import { arrayRemove } from "../../utils/arrayRemove";
import {
  ADD_REVISION_ANSWERS_SUCCESS,
  ADD_REVISION_RESULTS_SUCCESS,
  ADD_REVISION_ANSWERS_FAIL,
  GET_REVISION_ANSWERS_SUCCESS,
  GET_REVISION_ANSWERS_FAIL,
  GET_REVISIONS_ANSWERS_SUCCESS,
  GET_REVISIONS_ANSWERS_FAIL,
  GET_LEC_REVISION_ANS_SUCCESS,
  // GET_LEC_REVISION_ANS_FAIL,
  ABANDON_REVISION,
  UNDO_REVISION_ANS,
  LOGOUT,
  DELETE_REVISION_ANSSHEET_SUCCESS,
  // DELETE_ANSSHEET_FAIL,
} from "../types";

const RevisionReducer = (state, action) => {
  switch (action.type) {
    case ADD_REVISION_ANSWERS_SUCCESS:
    case GET_REVISIONS_ANSWERS_SUCCESS:
      return {
        ...state,
        // exams: [action.payload, ...state.exams],
        revisionAnswerSheets: action.payload,
        newRevisionAnswers: [],
      };
    case LOGOUT:
      return {
        ...state,
        revisionAnswerSheets: null,
      };
    case ABANDON_REVISION:
      return {
        ...state,
        newRevisionAnswers: [],
      };
    case UNDO_REVISION_ANS:
      return {
        ...state,
        newRevisionAnswers:
          // Array.isArray(state.newRevisionAnswers) &&
          state.newRevisionAnswers.filter(
            (revisionAnswer) => revisionAnswer.questionId !== action.payload
          ),
      };
    case GET_REVISION_ANSWERS_SUCCESS:
      return {
        ...state,
        newRevisionAnswers: action.payload,
      };
    case GET_LEC_REVISION_ANS_SUCCESS:
      return {
        ...state,
        lecRevisionAnsSheets: action.payload,
      };
    case ADD_REVISION_RESULTS_SUCCESS:
      return {
        ...state,
        results: action.payload,
      };

    case DELETE_REVISION_ANSSHEET_SUCCESS:
      return {
        ...state,
        revisionAnswerSheets: state.revisionAnswerSheets.filter(
          (ansSheet) => ansSheet._id !== action.payload
        ),
      };

    case ADD_REVISION_ANSWERS_FAIL:
    case GET_REVISION_ANSWERS_FAIL:
    case GET_REVISIONS_ANSWERS_FAIL:
      return {
        ...state,
        revisionAnswerSheets: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default RevisionReducer;
