import React from "react";
import Navbar from "./../components/layout/Navbar";
import Hero from "./../components/layout/Hero";
import Features from "./../components/layout/Features";
import Testimonials from "./../components/layout/Testimonials";
import CTA from "./../components/layout/CTA";
import Footer from "./../components/layout/Footer";

const LandingPage = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <Features />
      <Testimonials />
      <CTA />
      <Footer />
    </div>
  );
};

export default LandingPage;
