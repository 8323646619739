import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import useAuth from "../hooks/useAuth";
import Navbar from "./layout/Navbar";
import Footer from "./layout/Footer";

const VerifyUser = ({ component: Component, ...rest }) => {
  const params = useParams();
  const { loadUser, user } = useAuth();

  useEffect(() => {
    loadUser();
    async function fetcData() {
      // await axios.get(`/api/v1/auth/verify/${params.userId}`);
      await axios.get(`/api/v1/users/verify/${params.userId}`);
    }
    fetcData();

    // eslint-disable-next-line
  }, []);

  let firstName;

  if (user) firstName = user.name.split(" ")[0];

  return (
    <div>
      <Navbar />
      <div className="relative container mx-auto  min-h-screen flex flex-col  py-12 px-6 lg:px-8">
        <h3>Congratulations {firstName}!</h3>
        <p>Your account is now verified. You can now Login</p>
        <p>Happy Exams!</p>
      </div>
      <Footer />
    </div>
  );
};

export default VerifyUser;
