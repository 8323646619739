import React, { useReducer } from "react";
import axios from "axios";

import AuthContext from "./AuthContext";
import AuthReducer from "./AuthReducer";
import setAuthToken from "../../utils/setAuthToken";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
  LECTURER_APPROVAL_SUCCESS,
  LECTURER_APPROVAL_FAIL,
  PASSWORD_UPDATE_SUCCESS,
  PASSWORD_UPDATE_FAIL,
  PASSWORD_REQ_SUCCESS,
  PASSWORD_REQ_FAIL,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  GET_UNAPPROVED_LECTURERS,
  UNAPPROVED_LECTURERS_ERROR,
} from "../types";
import ExamReducer from "../exam/ExamReducer";
import ExamAnsReducer from "../answer/ExamAnsReducer";
import RegExamReducer from "../registerexam/RegExamReducer";

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: null,
    // isApproved: null,
    loading: true,
    user: null,
    error: null,
    unApprovedLecturers: [],
    approvedLecturer: null,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);
  const [examDispatch] = useReducer(ExamReducer);
  const [examAnsDispatch] = useReducer(ExamAnsReducer);
  const [regExamDispatch] = useReducer(RegExamReducer);

  // Load user
  const loadUser = async () => {
    // @todo - load token into global headers
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    // console.log(axios.defaults.headers.common);

    try {
      const res = await axios.get("/api/v1/users/me");

      // console.log(res.data);

      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    } catch (err) {
      // console.log(err.response.data);
      dispatch({ type: AUTH_ERROR });
    }
  };

  // Register user
  const register = async (formData) => {
    // console.log(formData.name);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // console.log(formData?.role);

    try {
      const res = await axios.post(
        "/api/v1/users/signup",
        {
          name: formData.name,
          email: formData.email,
          role: formData?.role,
          studentDetails: formData.studentDetails,
          password: formData.password,
          passwordConfirm: formData.passwordConfirm,
        },
        config
      );

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });

      loadUser();
    } catch (err) {
      // console.log(err);
      dispatch({
        type: REGISTER_FAIL,
        payload: err.response.data.error,
      });
    }
  };

  // Login user
  const login = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/v1/users/login", formData, config);
      // console.log(res.data);
      // console.log("??????");

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      // if (res) return loadUser(); // removed because it was returning errors
    } catch (err) {
      // console.log(err.response.data);
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response.data.message,
      });
    }
  };

  // forgotPassword
  const forgotPassword = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `/api/v1/users/forgotpassword`,
        {
          email: formData.email,
        },
        config
      );

      dispatch({
        type: PASSWORD_REQ_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: PASSWORD_REQ_FAIL,
        payload: err.response.data.error,
      });
    }
  };

  // Reset password
  const resetPassword = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.patch(
        `/api/v1/users/resetpassword/${formData.resetPasswordToken}`,
        {
          password: formData.password,
          passwordConfirm: formData.password2,
        },
        config
      );

      dispatch({
        type: PASSWORD_RESET_SUCCESS,
        payload: res.data,
      });

      loadUser();
    } catch (err) {
      dispatch({
        type: PASSWORD_RESET_FAIL,
        payload: err.response.data.error,
      });
    }
  };

  // updatePassword
  const updatePassword = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.patch(
        // `/api/v1/users/updatepassword/${formData.userId}`,
        `/api/v1/users/updatepassword`,
        {
          currentPassword: formData.currentPassword,
          password: formData.password,
          passwordConfirm: formData.password2,
        },
        config
      );

      // console.log(res.data);

      dispatch({
        type: PASSWORD_UPDATE_SUCCESS,
        payload: res.data,
      });

      loadUser();
    } catch (err) {
      dispatch({
        type: PASSWORD_UPDATE_FAIL,
        payload: err.response.data.error,
      });
    }
  };

  // Logout
  const logOut = () => {
    dispatch({
      type: LOGOUT,
    });
    examDispatch({
      type: LOGOUT,
    });
    examAnsDispatch({
      type: LOGOUT,
    });
    regExamDispatch({
      type: LOGOUT,
    });
  };

  // Clear errors
  const clearErrors = () => {
    dispatch({
      type: CLEAR_ERRORS,
    });
  };

  // approve a member
  const approveLecturer = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `/api/v1/users/approvelecturer/${formData.newMemberId}`,
        {
          approvedBy: formData.userId,
        },
        config
      );

      // console.log(res);

      // const accountNumber = `${new Date().toLocaleDateString("en-ZA", {
      //   year: "numeric",
      //   day: "2-digit",
      //   month: "2-digit",
      // })}${Math.floor(1000 + Math.random() * 9000)}`.replaceAll("/", "");

      // const acc = await axios.post(
      //   "/api/v1/chartofaccounts",
      //   {
      //     name: formData.name,
      //     number: accountNumber,
      //     type: "member",
      //     description: "user account",
      //   },
      //   config
      // );

      dispatch({
        type: LECTURER_APPROVAL_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: LECTURER_APPROVAL_FAIL,
        payload: err.response.data.error,
      });
    }
  };

  // get all unapproved members
  const getUnApprovedLecturers = async () => {
    try {
      const res = await axios.get("/api/v1/users/unapprovedlecturers");
      // console.log(res.data.data);

      dispatch({
        type: GET_UNAPPROVED_LECTURERS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({ type: UNAPPROVED_LECTURERS_ERROR });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        // isApproved: state.isApproved,
        loading: state.loading,
        user: state.user,
        error: state.error,
        unApprovedLecturers: state.unApprovedLecturers,
        approvedLecturer: state.approvedLecturer,
        register,
        loadUser,
        login,
        logOut,
        clearErrors,
        getUnApprovedLecturers,
        approveLecturer,
        updatePassword,
        forgotPassword,
        resetPassword,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
