import React from "react";

function WelcomeBanner({ user, msg }) {
  return (
    <div className="relative  bg-brightRedSupLight p-4 sm:p-6 rounded-3xl shadow-lg overflow-hidden mb-8">
      {/* Background illustration */}
      <div
        className="absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden xl:block"
        aria-hidden="true"
      ></div>

      {/* Content */}
      <div className="relative ">
        <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mb-1">
          {`Hi ${
            user ? user.name.split(" ")[0] : "! Welcome to Examaholics"
          }! 👋`}
        </h1>
        <p>{msg ? msg : "Here is your history and schedured exams:"}</p>
      </div>
    </div>
  );
}

export default WelcomeBanner;
