import React, { useReducer } from "react";
import axios from "axios";

import RevisionAnsContext from "./RevisionAnsContext";
import RevisionAnsReducer from "./RevisionAnsReducer";

import {
  ADD_REVISION_ANSWERS_SUCCESS,
  ADD_REVISION_RESULTS_SUCCESS,
  ADD_REVISION_ANSWERS_FAIL,
  GET_REVISION_ANSWERS_SUCCESS,
  GET_REVISION_ANSWERS_FAIL,
  GET_REVISIONS_ANSWERS_SUCCESS,
  GET_REVISIONS_ANSWERS_FAIL,
  GET_LEC_REVISION_ANS_SUCCESS,
  GET_LEC_REVISION_ANS_FAIL,
  ABANDON_REVISION,
  UNDO_REVISION_ANS,
  DELETE_REVISION_ANSSHEET_SUCCESS,
  DELETE_REVISION_ANSSHEET_FAIL,
} from "../types";
// import isEmail from "validator/lib/isEmail";

const RevisionAnsState = (props) => {
  const initialState = {
    revisionAnswerSheets: [],
    newRevisionAnswers: [],
    lecRevisionAnsSheets: [],
    results: null,
    error: null,
  };

  const [state, dispatch] = useReducer(RevisionAnsReducer, initialState);

  // MongoDB Actions

  // add new question
  async function addRevisionAnswerSheet(revisionAnswerSheet) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // console.log("logging from state side");
    try {
      const res = await axios.post(
        "/api/v1/revisionanswers",
        revisionAnswerSheet,
        config
      );

      // console.log(res);
      // if(res){
      //   new Email()
      // }

      // for inApp display of results
      dispatch({
        type: ADD_REVISION_RESULTS_SUCCESS,
        payload: res.data.data.data,
      });

      dispatch({
        type: ADD_REVISION_ANSWERS_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: ADD_REVISION_ANSWERS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // get one exam
  async function getRevisionAnswerSheet(examId) {
    // console.log("logging from state side");
    try {
      const res = await axios.get(`/api/v1/revisionanswers/${examId}`);
      // console.log(res.data.data.data);

      dispatch({
        type: GET_REVISION_ANSWERS_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_REVISION_ANSWERS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // get all exam
  async function getAllRevisionAnswerSheets(userId) {
    const searchParams = userId ? "?userId=" + userId : "";
    // console.log("logging from state side");
    try {
      const res = await axios.get(`/api/v1/revisionanswers${searchParams}`);
      // console.log(res.data.data.data);

      dispatch({
        type: GET_REVISIONS_ANSWERS_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_REVISIONS_ANSWERS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // get all exam results
  async function getAllRevisionResults(examId) {
    const searchParams = examId ? "?examId=" + examId : "";
    // console.log("logging from state side");
    try {
      const res = await axios.get(`/api/v1/revisionanswers${searchParams}`);
      // console.log(res.data.data.data);

      dispatch({
        type: GET_REVISIONS_ANSWERS_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_REVISIONS_ANSWERS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }
  // get all exam
  async function getAllLecturerRevisionAnswerSheets(examId) {
    // const searchParams = examId ? "?userId=" + examId : "";
    // console.log("logging from state side");
    try {
      const res = await axios.get(`/api/v1/${examId}/answersheets`);
      // console.log(res.data.data.data);

      dispatch({
        type: GET_LEC_REVISION_ANS_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_LEC_REVISION_ANS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // get current answersheet
  async function getCurrentAnswerSheets(userId, examId) {
    const searchParams = "?userId=" + userId + "&examId=" + examId;
    // console.log("logging from state side");
    try {
      const res = await axios.get(`/api/v1/revisionanswers${searchParams}`);
      // console.log(res.data.data.data);

      dispatch({
        type: GET_REVISION_ANSWERS_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_REVISION_ANSWERS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // abandon exam
  function abandonRevision() {
    dispatch({
      type: ABANDON_REVISION,
      // payload: res.data.data,
    });
  }

  // undo exam answer
  function undoRevisionAnswer(questionId) {
    // if (!state.newExamAnswers.includes(questionId)) return;
    dispatch({
      type: UNDO_REVISION_ANS,
      payload: questionId,
    });

    // console.log(
    //   state.newExamAnswers.find((el) => el.questionId === questionId).questionId
    // );
    // console.log(questionId);
  }

  async function deleteStudentAnsheet(ansSheetId) {
    try {
      // const res =
      await axios.delete(`/api/v1/revisionanswers/${ansSheetId}`);
      // console.log(res.data.data.data);

      dispatch({
        type: DELETE_REVISION_ANSSHEET_SUCCESS,
        payload: ansSheetId,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: DELETE_REVISION_ANSSHEET_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  return (
    <RevisionAnsContext.Provider
      value={{
        revisionAnswerSheets: state.revisionAnswerSheets,
        newRevisionAnswers: state.newRevisionAnswers,
        lecRevisionAnsSheets: state.lecRevisionAnsSheets,
        results: state.results,
        error: state.error,
        abandonRevision,
        undoRevisionAnswer,
        addRevisionAnswerSheet,
        getRevisionAnswerSheet,
        getAllRevisionAnswerSheets,
        getAllLecturerRevisionAnswerSheets,
        getCurrentAnswerSheets,
        getAllRevisionResults,
        deleteStudentAnsheet,
      }}
    >
      {props.children}
    </RevisionAnsContext.Provider>
  );
};

export default RevisionAnsState;
