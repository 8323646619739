import React from "react";

import Footer from "../components/layout/Footer";
import Navbar from "../components/layout/Navbar";

import Register from "../components/Register";

const LoginPage = () => {
  return (
    <div>
      <Navbar />
      <Register />
      <Footer />
    </div>
  );
};

export default LoginPage;
