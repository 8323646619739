import React from "react";

const Features = () => {
  return (
    <div>
      {/* Flex Container */}
      <div className="container flex flex-col md:flex-row px-4 mx-auto mt-10 space-y-12 md:space-y-0 ">
        {/* What's the Difference */}
        <div className="flex flex-col space-y-12 md:w-1/2">
          <h2 className="max-w-md text-4xl font-bold text-center md:text-left">
            What's different about Examaholics?
          </h2>
          <p className="max-w-sm text-center text-darkGrayishBlue md:text-left">
            Examaholics provides all the functionality both students and
            lecturers need, without the complexity. Our software is tailor-made
            for modern elearning and assessments.
          </p>
        </div>
        {/* Numbered List */}
        <div className="flex flex-col space-y-8 md:w-1/2">
          {/* List Item 1 */}
          <div className="flex flex-col spaces-y-3 md:space-y-0 md:space-x-6 md:flex-row">
            {/* Heading */}
            <div className="rounded-l-full bg-brightRedSupLight md:bg-transparent">
              <div className="flex items-center space-x-2">
                <div className="px-4 py-2 text-white rounded-full md:py-1 bg-brightRed">
                  01
                </div>
                <h3 className="text-base font-bold md:mb-4 md:hidden">
                  Track preparation progess
                </h3>
              </div>
            </div>
            <div>
              <h3 className="hidden mb-4 text-lg font-bold md:block">
                Track preparation progess
              </h3>
              <p className="text-darkGrayishBlue">
                see how your exam preparations fit into the wider vision of
                learning. Go from tracking progess at the milestone level all
                the way done to the smallest of details. Never lose sight of the
                biggeer picture again.
              </p>
            </div>
          </div>
          {/* List Item 2 */}
          <div className="flex flex-col spaces-y-3 md:space-y-0 md:space-x-6 md:flex-row">
            {/* Heading */}
            <div className="rounded-l-full bg-brightRedSupLight md:bg-transparent">
              <div className="flex items-center space-x-2">
                <div className="px-4 py-2 text-white rounded-full md:py-1 bg-brightRed">
                  02
                </div>
                <h3 className="text-base font-bold md:mb-4 md:hidden">
                  Advanced built-in reports
                </h3>
              </div>
            </div>
            <div>
              <h3 className="hidden mb-4 text-lg font-bold md:block">
                Advanced built-in reports
              </h3>
              <p className="text-darkGrayishBlue">
                Get a reports on your progess as you practice on the platform
                and track progress towards your goals. Lecturers will get
                reports on students performance upon completion of exams. Our
                customizable dashboard helps you build out the reports you need
                to keep key stakeholders informed.
              </p>
            </div>
          </div>
          {/* List Item 3 */}
          <div className="flex flex-col spaces-y-3 md:space-y-0 md:space-x-6 md:flex-row">
            {/* Heading */}
            <div className="rounded-l-full bg-brightRedSupLight md:bg-transparent">
              <div className="flex items-center space-x-2">
                <div className="px-4 py-2 text-white rounded-full md:py-1 bg-brightRed">
                  03
                </div>
                <h3 className="text-base font-bold md:mb-4 md:hidden">
                  Everything you need in one place
                </h3>
              </div>
            </div>
            <div>
              <h3 className="hidden mb-4 text-lg font-bold md:block">
                Everything you need in one place
              </h3>
              <p className="text-darkGrayishBlue">
                Stop jumping from one revision book to another to prepare, store
                questions, track progress, administer exams and share reports.
                Examaholics offers and all-in-one exam productivity solution.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
