import {
  ADD_REG_EXAM_SUCCESS,
  ADD_REG_EXAM_FAIL,
  GET_REG_EXAMS_SUCCESS,
  GET_REG_EXAMS_FAIL,
  GET_EXAM_REGS_SUCCESS,
  GET_EXAM_REGS_FAIL,
  LOGOUT,
  DELETE_EXAM_REG_SUCCESS,
  // DELETE_EXAM_REG_FAIL,
} from "../types";

const RegExamReducer = (state, action) => {
  switch (action.type) {
    case ADD_REG_EXAM_SUCCESS:
      return {
        ...state,
        registerExams: [action.payload, ...state.registerExams],
      };
    case DELETE_EXAM_REG_SUCCESS:
      return {
        ...state,
        examRegistrations: state.examRegistrations.filter(
          (regExam) => regExam._id !== action.payload
        ),
      };

    case GET_REG_EXAMS_SUCCESS:
      return {
        ...state,
        registerExams: action.payload,
      };
    case GET_EXAM_REGS_SUCCESS:
      return {
        ...state,
        examRegistrations: action.payload,
      };
    case GET_EXAM_REGS_FAIL:
      return {
        ...state,
        examRegistrations: [],
      };
    case LOGOUT:
      return {
        ...state,
        registerExams: null,
      };

    case ADD_REG_EXAM_FAIL:
    case GET_REG_EXAMS_FAIL:
      return {
        ...state,
        registerExams: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default RegExamReducer;
