import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import useExam from "../../hooks/useExam";
import ExamDetails from "./ExamDetails";
import ExamQuestions from "./ExamQuestions";

const ExamPaper = () => {
  const { exam, getExam } = useExam();
  const { id } = useParams();
  const effectRan = useRef();
  useEffect(() => {
    // if (effectRan.current === false) {
    getExam(id);
    return () => {
      // effectRan.current = true;
    };
    // }
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      {/* {exam && <ExamDetails exam={exam} />} */}
      {exam && (
        <ExamQuestions
          exam={exam}
          questions={exam.questions}
          examId={exam._id}
          title={exam.title}
        />
      )}
    </div>
  );
};

export default ExamPaper;
