// import { arrayRemove } from "../../utils/arrayRemove";
import {
  ADD_EXAM_ANSWERS_SUCCESS,
  ADD_RESULTS_SUCCESS,
  ADD_EXAM_ANSWERS_FAIL,
  GET_EXAM_ANSWERS_SUCCESS,
  GET_EXAM_ANSWERS_FAIL,
  GET_EXAMS_ANSWERS_SUCCESS,
  GET_EXAMS_ANSWERS_FAIL,
  GET_LEC_EXAM_ANS_SUCCESS,
  // GET_LEC_EXAM_ANS_FAIL,
  ABANDON_EXAM,
  UNDO_EXAM_ANS,
  LOGOUT,
  DELETE_ANSSHEET_SUCCESS,
  // DELETE_ANSSHEET_FAIL,
} from "../types";

const ExamReducer = (state, action) => {
  switch (action.type) {
    case ADD_EXAM_ANSWERS_SUCCESS:
    case GET_EXAMS_ANSWERS_SUCCESS:
      return {
        ...state,
        // exams: [action.payload, ...state.exams],
        examAnswerSheets: action.payload,
        newExamAnswers: [],
      };
    case LOGOUT:
      return {
        ...state,
        examAnswerSheets: null,
      };
    case ABANDON_EXAM:
      return {
        ...state,
        newExamAnswers: [],
      };
    case UNDO_EXAM_ANS:
      return {
        ...state,
        newExamAnswers: state.newExamAnswers.filter(
          (examAnswer) => examAnswer.questionId !== action.payload
        ),
      };
    case GET_EXAM_ANSWERS_SUCCESS:
      return {
        ...state,
        newExamAnswers: action.payload,
      };
    case GET_LEC_EXAM_ANS_SUCCESS:
      return {
        ...state,
        lecExamAnsSheets: action.payload,
      };
    case ADD_RESULTS_SUCCESS:
      return {
        ...state,
        results: action.payload,
      };

    case DELETE_ANSSHEET_SUCCESS:
      return {
        ...state,
        examAnswerSheets: state.examAnswerSheets.filter(
          (ansSheet) => ansSheet._id !== action.payload
        ),
      };

    case ADD_EXAM_ANSWERS_FAIL:
    case GET_EXAM_ANSWERS_FAIL:
    case GET_EXAMS_ANSWERS_FAIL:
      return {
        ...state,
        examAnswerSheets: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default ExamReducer;
