import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "./../img/examaholics-logo.svg";

import AuthContext from "../context/auth/AuthContext";
import AlertContext from "../context/alert/AlertContext";

const Register = () => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const [isDisabled, setIsDisabled] = useState(false);

  let navigate = useNavigate();

  const { setAlert } = alertContext;
  const { register, error, clearErrors, isAuthenticated } = authContext;

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    if (isMounted && isAuthenticated) {
      navigate("/verify");
    }

    if (error === "Invalid credentials") {
      setAlert(error, "danger");
      clearErrors();
    }

    return () => {
      isMounted = false;
      controller.abort();
    };

    // eslint-disable-next-line
  }, [error, isAuthenticated, navigate]);

  // 1. student ID number
  // 2. programme studying
  // 3. year of Batch Intake
  // 4. school
  // 5. university
  const [newStudentDetails, setNewStudentDetails] = useState({
    computerNumber: "",
    programme: "",
    intake: "",
    school: "",
    university: "",
  });

  const { computerNumber, programme, intake, school, university } =
    newStudentDetails;

  const [newUser, setNewUser] = useState({
    forename: "",
    lastname: "",
    studentDetails: {},
    email: "",
    password: "",
    passwordConfirm: "",
    // checkbox: false,
  });

  const {
    forename,
    lastname,
    studentDetails,
    email,
    password,
    passwordConfirm,
    // checkbox
  } = newUser;

  const onChange = (e) => {
    setNewStudentDetails({
      ...newStudentDetails,
      [e.target.name]: e.target.value,
    });
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      email === "" ||
      password === "" ||
      studentDetails === {} ||
      forename === "" ||
      lastname === ""
      // || checkbox !== true
    ) {
      setAlert("Please enter all fields", "danger");
    } else if (password !== passwordConfirm) {
      setAlert("Password and password confirm must be equal", "danger");
    } else {
      const name = `${forename} ${lastname}`;

      register({
        name,
        email,
        studentDetails: {
          computerNumber: newStudentDetails.computerNumber,
          programme: newStudentDetails.programme,
          intake: newStudentDetails.intake,
          school: newStudentDetails.school,
          university: newStudentDetails.university,
        },
        password,
        passwordConfirm,
      });
      // console.log("register clicked");
      setIsDisabled(true);
    }
  };

  return (
    <div className="min-h-screen flex flex-col  py-12 px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={logo} alt="Examaholics" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Create your account
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600 max-w">
          Already registered?
          <Link
            to="/login"
            className="font-medium text-brightRed hover:text-brightRedLight focus:outline-none focus:ring-2 focus:ring-brightRedLight"
          >
            {" "}
            Sign in
          </Link>
        </p>

        {/* register as a lecturer */}
        <p className="mt-2 text-center text-sm text-gray-600 max-w">
          Register as a lecturer
          <Link
            to="/registerlecturer"
            className="font-medium text-brightRed hover:text-brightRedLight focus:outline-none focus:ring-2 focus:ring-brightRedLight"
          >
            {" "}
            Sign up
          </Link>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-6 shadow rounded-3xl sm:px-10">
          <form
            className="mb-0 space-y-6"
            onSubmit={onSubmit}
            // action="#"
            // method="POST"
          >
            <div>
              <label
                htmlFor="forename"
                className="block text-sm font-medium text-gray-700"
              >
                Forename(s)
              </label>
              <div className="mt-1">
                <input
                  id="forename"
                  name="forename"
                  type="text"
                  autoComplete="forename"
                  value={forename}
                  onChange={onChange}
                  placeholder="Enter first name(s)..."
                  required
                  className=""
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="lastname"
                className="block text-sm font-medium text-gray-700"
              >
                Last name
              </label>
              <div className="mt-1">
                <input
                  id="lastname"
                  name="lastname"
                  type="text"
                  autoComplete="lastname"
                  value={lastname}
                  onChange={onChange}
                  placeholder="Enter last name..."
                  required
                  className=""
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="computerNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Computer Number
              </label>
              <div className="mt-1">
                <input
                  id="computerNumber"
                  name="computerNumber"
                  type="text"
                  autoComplete="computerNumber"
                  value={computerNumber}
                  onChange={onChange}
                  placeholder="Enter Computer Number..."
                  required
                  className=""
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="programme"
                className="block text-sm font-medium text-gray-700"
              >
                Programme
              </label>
              <div className="mt-1">
                <input
                  id="programme"
                  name="programme"
                  type="text"
                  autoComplete="programme"
                  value={programme}
                  onChange={onChange}
                  placeholder="Enter Programme..."
                  required
                  className=""
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="intake"
                className="block text-sm font-medium text-gray-700"
              >
                Year of batch Intake
              </label>
              <div className="mt-1">
                <input
                  id="intake"
                  name="intake"
                  type="text"
                  autoComplete="intake"
                  value={intake}
                  onChange={onChange}
                  placeholder="Enter Year of batch Intake..."
                  required
                  className=""
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="school"
                className="block text-sm font-medium text-gray-700"
              >
                School
              </label>
              <div className="mt-1">
                <input
                  id="school"
                  name="school"
                  type="text"
                  autoComplete="school"
                  value={school}
                  onChange={onChange}
                  placeholder="Enter School..."
                  required
                  className=""
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="university"
                className="block text-sm font-medium text-gray-700"
              >
                University
              </label>
              <div className="mt-1">
                <input
                  id="university"
                  name="university"
                  type="text"
                  autoComplete="university"
                  value={university}
                  onChange={onChange}
                  placeholder="Enter University..."
                  required
                  className=""
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={onChange}
                  placeholder="Enter email..."
                  required
                  className=""
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="password"
                  value={password}
                  onChange={onChange}
                  placeholder="Enter password..."
                  className=""
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Confirm password
              </label>
              <div className="mt-1">
                <input
                  id="passwordConfirm"
                  name="passwordConfirm"
                  type="password"
                  autoComplete="passwordConfirm"
                  value={passwordConfirm}
                  onChange={onChange}
                  placeholder="Confirm password..."
                  required
                  className=""
                />
              </div>
            </div>

            {/* <div>
              <label
                htmlFor="company-size"
                className="block text-sm font-medium text-gray-700"
              >
                Company size
              </label>
              <div className="mt-1">
                <select name="company-size" id="company-size" className="">
                  <option value="">Please select</option>
                  <option value="small">1 to 10 employees</option>
                  <option value="medium">11 to 50 employees</option>
                  <option value="large">50+ employees</option>
                </select>
              </div>
            </div> */}

            <div className="flex items-center">
              <input
                id="terms-and-privacy"
                name="terms-and-privacy"
                type="checkbox"
                // value={checkbox}
                onChange={onChange}
                className=""
                required
              />
              <label
                htmlFor="terms-and-privacy"
                className="ml-2 block text-sm text-gray-900"
              >
                I agree to the
                <Link
                  to="#"
                  className="text-brightRed hover:text-brightRedLight"
                >
                  {" "}
                  Terms{" "}
                </Link>
                and
                <Link
                  to="#"
                  className="text-brightRed hover:text-brightRedLight"
                >
                  {" "}
                  Privacy Policy
                </Link>
                .
              </label>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-brightRed hover:bg-brightRedLight focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brightRedSupLight"
                disabled={isDisabled}
              >
                Sign up
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
