import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import EditExam from "../components/layout/EditExam";
import useExam from "../hooks/useExam";

const EditExamPage = () => {
  const params = useParams();
  const { exam, getExam } = useExam();

  useEffect(() => {
    getExam(params.id);
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* add quesion form */}
      <EditExam exam={exam} />
    </>
  );
};

export default EditExamPage;
