import React, { useContext } from "react";

import WelcomeBanner from "../components/layout/WelcomeBanner";
import AuthContext from "../context/auth/AuthContext";
import RevisionAnswers from "./RevisionAnswers";

import RevisionResults from "./RevisionResults";

const RevisionResultsPage = () => {
  const authContext = useContext(AuthContext);

  return (
    <>
      {/* Welcome banner */}
      <WelcomeBanner user={authContext.user} msg={"Here are your results:"} />
      <RevisionResults />
      <RevisionAnswers />
    </>
  );
};

export default RevisionResultsPage;
