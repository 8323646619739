import React, { useContext } from "react";

import WelcomeBanner from "../components/layout/WelcomeBanner";

import AuthContext from "../context/auth/AuthContext";

import AddQuestion from "../components/layout/AddQuestion";

const AddQuestionPage = () => {
  const authContext = useContext(AuthContext);

  return (
    <>
      {/* Welcome banner */}
      <WelcomeBanner
        user={authContext.user}
        msg={"You can add your questions from here:"}
      />
      {/* <LinksSection /> */}
      {/* add quesion form */}
      <AddQuestion user={authContext.user} />
    </>
  );
};

export default AddQuestionPage;
