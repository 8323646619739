import {
  ADD_REG_REVISION_SUCCESS,
  ADD_REG_REVISION_FAIL,
  GET_REG_REVISIONS_SUCCESS,
  GET_REG_REVISIONS_FAIL,
  GET_REVISION_REGS_SUCCESS,
  GET_REVISION_REGS_FAIL,
  LOGOUT,
  DELETE_REVISION_REG_SUCCESS,
  // DELETE_EXAM_REG_FAIL,
} from "../types";

const RegRevisionReducer = (state, action) => {
  switch (action.type) {
    case ADD_REG_REVISION_SUCCESS:
      return {
        ...state,
        registerRevisions: [action.payload, ...state.registerRevisions],
      };
    case DELETE_REVISION_REG_SUCCESS:
      return {
        ...state,
        revisionRegistrations: state.revisionRegistrations.filter(
          (regRevision) => regRevision._id !== action.payload
        ),
      };

    case GET_REG_REVISIONS_SUCCESS:
      return {
        ...state,
        registerRevisions: action.payload,
      };
    case GET_REVISION_REGS_SUCCESS:
      return {
        ...state,
        revisionRegistrations: action.payload,
      };
    case GET_REVISION_REGS_FAIL:
      return {
        ...state,
        revisionRegistrations: [],
      };
    case LOGOUT:
      return {
        ...state,
        registerRevisions: null,
      };

    case ADD_REG_REVISION_FAIL:
    case GET_REG_REVISIONS_FAIL:
      return {
        ...state,
        registerRevisions: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default RegRevisionReducer;
