import React, { useReducer } from "react";
import axios from "axios";

import ExamAnsContext from "./ExamAnsContext";
import ExamAnsReducer from "./ExamAnsReducer";

import {
  ADD_EXAM_ANSWERS_SUCCESS,
  ADD_RESULTS_SUCCESS,
  ADD_EXAM_ANSWERS_FAIL,
  GET_EXAM_ANSWERS_SUCCESS,
  GET_EXAM_ANSWERS_FAIL,
  GET_EXAMS_ANSWERS_SUCCESS,
  GET_EXAMS_ANSWERS_FAIL,
  GET_LEC_EXAM_ANS_SUCCESS,
  GET_LEC_EXAM_ANS_FAIL,
  ABANDON_EXAM,
  UNDO_EXAM_ANS,
  DELETE_ANSSHEET_SUCCESS,
  DELETE_ANSSHEET_FAIL,
} from "../types";
// import isEmail from "validator/lib/isEmail";

const ExamAnsState = (props) => {
  const initialState = {
    examAnswerSheets: [],
    newExamAnswers: [],
    lecExamAnsSheets: [],
    results: null,
    error: null,
  };

  const [state, dispatch] = useReducer(ExamAnsReducer, initialState);

  // MongoDB Actions

  // add new question
  async function addExamAnswerSheet(examAnswerSheet) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // console.log("logging from state side");
    try {
      const res = await axios.post("/api/v1/answers", examAnswerSheet, config);

      // console.log(res);
      // if(res){
      //   new Email()
      // }

      // for inApp display of results
      dispatch({
        type: ADD_RESULTS_SUCCESS,
        payload: res.data.data.data,
      });

      dispatch({
        type: ADD_EXAM_ANSWERS_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: ADD_EXAM_ANSWERS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // get one exam
  async function getExamAnswerSheet(examId) {
    // console.log("logging from state side");
    try {
      const res = await axios.get(`/api/v1/answers/${examId}`);
      // console.log(res.data.data.data);

      dispatch({
        type: GET_EXAM_ANSWERS_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_EXAM_ANSWERS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // get all exam
  async function getAllExamAnswerSheets(userId) {
    const searchParams = userId ? "?userId=" + userId : "";
    // console.log("logging from state side");
    try {
      const res = await axios.get(`/api/v1/answers${searchParams}`);
      // console.log(res.data.data.data);

      dispatch({
        type: GET_EXAMS_ANSWERS_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_EXAMS_ANSWERS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // get all exam results
  async function getAllExamResults(examId) {
    const searchParams = examId ? "?examId=" + examId : "";
    // console.log("logging from state side");
    try {
      const res = await axios.get(`/api/v1/answers${searchParams}`);
      // console.log(res.data.data.data);

      dispatch({
        type: GET_EXAMS_ANSWERS_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_EXAMS_ANSWERS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }
  // get all exam
  async function getAllLecturerExamAnswerSheets(examId) {
    // const searchParams = examId ? "?userId=" + examId : "";
    // console.log("logging from state side");
    try {
      const res = await axios.get(`/api/v1/${examId}/answersheets`);
      // console.log(res.data.data.data);

      dispatch({
        type: GET_LEC_EXAM_ANS_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_LEC_EXAM_ANS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // get current answersheet
  async function getCurrentAnswerSheets(userId, examId) {
    const searchParams = "?userId=" + userId + "&examId=" + examId;
    // console.log("logging from state side");
    try {
      const res = await axios.get(`/api/v1/answers${searchParams}`);
      // console.log(res.data.data.data);

      dispatch({
        type: GET_EXAM_ANSWERS_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_EXAM_ANSWERS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // abandon exam
  function abandonExam() {
    dispatch({
      type: ABANDON_EXAM,
      // payload: res.data.data,
    });
  }

  // undo exam answer
  function undoExamAnswer(questionId) {
    // if (!state.newExamAnswers.includes(questionId)) return;
    dispatch({
      type: UNDO_EXAM_ANS,
      payload: questionId,
    });

    // console.log(
    //   state.newExamAnswers.find((el) => el.questionId === questionId).questionId
    // );
    // console.log(questionId);
  }

  async function deleteStudentAnsheet(ansSheetId) {
    try {
      // const res =
      await axios.delete(`/api/v1/answers/${ansSheetId}`);
      // console.log(res.data.data.data);

      dispatch({
        type: DELETE_ANSSHEET_SUCCESS,
        payload: ansSheetId,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: DELETE_ANSSHEET_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  return (
    <ExamAnsContext.Provider
      value={{
        examAnswerSheets: state.examAnswerSheets,
        newExamAnswers: state.newExamAnswers,
        lecExamAnsSheets: state.lecExamAnsSheets,
        results: state.results,
        error: state.error,
        abandonExam,
        undoExamAnswer,
        addExamAnswerSheet,
        getExamAnswerSheet,
        getAllExamAnswerSheets,
        getAllLecturerExamAnswerSheets,
        getCurrentAnswerSheets,
        getAllExamResults,
        deleteStudentAnsheet,
      }}
    >
      {props.children}
    </ExamAnsContext.Provider>
  );
};

export default ExamAnsState;
