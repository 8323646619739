// import setAuthToken from "../../utils/setAuthToken";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
  GET_UNAPPROVED_LECTURERS,
  UNAPPROVED_LECTURERS_ERROR,
  LECTURER_APPROVAL_SUCCESS,
  LECTURER_APPROVAL_FAIL,
  PASSWORD_UPDATE_SUCCESS,
  PASSWORD_UPDATE_FAIL,
  PASSWORD_REQ_SUCCESS,
  PASSWORD_REQ_FAIL,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
} from "../types";

const AuthReducer = (state, action) => {
  switch (action.type) {
    case USER_LOADED:
      // console.log("from AuthReducer");
      // localStorage.setItem("token", action.payload.token);
      // console.log(action.payload.data);
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload.data.data,
        // isApproved: action.payload.data.isApproved,
      };
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
    case PASSWORD_UPDATE_SUCCESS:
    case PASSWORD_RESET_SUCCESS:
      localStorage.setItem("token", action.payload.token);

      // setAuthToken(localStorage.getItem("token"));
      // setAuthToken(localStorage.token);

      // console.log({ ...action.payload });
      // console.log({ ...state, ...action.payload });
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loading: false,
      };

    case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case PASSWORD_UPDATE_FAIL:
    case PASSWORD_RESET_FAIL:
    case LOGOUT:
      // console.log(action.payload);
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        error: action.payload,
        // isApproved: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case GET_UNAPPROVED_LECTURERS:
      return {
        ...state,
        loading: false,
        unApprovedLecturers: action.payload,
      };
    case UNAPPROVED_LECTURERS_ERROR:
      return {
        ...state,
        unApprovedLecturers: null,
        error: action.payload,
      };
    case LECTURER_APPROVAL_SUCCESS:
      return {
        ...state,
        loading: false,
        approvedLecturer: action.payload,
      };
    case LECTURER_APPROVAL_FAIL:
      return {
        ...state,
        approvedLecturer: null,
        error: action.payload,
      };
    case PASSWORD_REQ_SUCCESS:
      return {
        ...state,
        loading: false,
        approvedUser: action.payload,
      };
    case PASSWORD_REQ_FAIL:
      return {
        ...state,
        approvedUser: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
