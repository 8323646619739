import React from "react";

const ExamControlButton = ({ text, textColor, bgColor, onClick, disabled }) => {
  return (
    <button
      onClick={onClick}
      className={`w-150 flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium ${
        textColor ? textColor : "text-white"
      } ${
        bgColor ? bgColor : " bg-brightRed hover:bg-brightRedLight"
      } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brightRedSupLight`}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default ExamControlButton;
