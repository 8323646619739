import React from "react";

import WelcomeBanner from "../components/layout/WelcomeBanner";

import useAuth from "./../hooks/useAuth";
import ExamsResultsList from "./ExamsResultsList";

const ExamsResultsPage = () => {
  const { user } = useAuth();

  return (
    <>
      {/* Welcome banner */}
      <WelcomeBanner user={user} msg={"See listed exams here:"} />
      <ExamsResultsList />
    </>
  );
};

export default ExamsResultsPage;
