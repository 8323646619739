import React, { useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const PrivateVerifiedRoute = () => {
  const { user, loading } = useAuth();
  const location = useLocation();
  // const navigate = useNavigate();

  useEffect(() => {
    // loadUser();
  }, [user]);

  let isVerified = false;
  let page;

  if (user) isVerified = user?.isVerified;

  if (user) {
    if (isVerified !== true) {
      page = "/notverified";
    } else {
      page = "/";
    }
  }

  if (user && !loading)
    return isVerified === true ? (
      <Outlet />
    ) : (
      <Navigate to={page} state={{ from: location }} replace />
    );

  if (!user) {
    setTimeout(() => {
      <Navigate to="" state={{ from: location }} replace />;
    }, 1000);
  }

  return <span>Loading.....</span>;
};

export default PrivateVerifiedRoute;
