import React, { useState, useContext, useEffect } from "react";
import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import ExamControlButton from "../reusableComponents/ExamControlButton";

const ForgotPassword = (props) => {
  const { setAlert } = useAlert();
  const { forgotPassword } = useAuth();

  const [isAddingTransaction, setIsAddingTransaction] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const [email, setEmail] = useState("");

  const onChange = (e) => setEmail(e.target.value);

  const onSubmit = (e) => {
    e.preventDefault();

    if (email === "") {
      setAlert("Please enter email", "danger");
    } else {
      forgotPassword({ email });
      setAlert("Password reset token sent to email", "success");
      setEmail("");
      setIsAddingTransaction(!isAddingTransaction);
    }
  };

  const toggleForm = () => {
    setIsAddingTransaction(!isAddingTransaction);
  };

  return (
    <div className="  min-h-screen flex flex-col  py-12 px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md ">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Forgot password
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600 max-w">
          Forgot password? enter the email you registered with to receive a
          password reset token.
        </p>
        {isAddingTransaction && (
          <form onSubmit={onSubmit}>
            <div className="form-control">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                name="email"
                value={email}
                onChange={onChange}
                required
                placeholder="Enter your email..."
              />
            </div>

            <div className="flex justify-center items-center mt-6">
              <ExamControlButton text={"Send"} />
            </div>
          </form>
        )}
        <div className="flex justify-center my-6 mx-auto items-center">
          <ExamControlButton
            text={isAddingTransaction ? "Cancel" : "Send reset token"}
            onClick={toggleForm}
            bgColor={isAddingTransaction ? "bg-gray-200" : ""}
            textColor={isAddingTransaction ? "text-gray-800" : ""}
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
