import React, { useReducer } from "react";
import axios from "axios";

import ExamContext from "./ExamContext";
import ExamReducer from "./ExamReducer";

import {
  ADD_EXAM_SUCCESS,
  ADD_EXAM_FAIL,
  GET_EXAMS_SUCCESS,
  GET_EXAMS_FAIL,
  GET_EXAM_SUCCESS,
  GET_EXAM_FAIL,
  ADD_QUESTION_TO_EXAM,
  REMOVE_QUESTION_FROM_EXAM,
  GET_LECTURER_EXAMS_SUCCESS,
  GET_LECTURER_EXAMS_FAIL,
  ADD_QUESTIONS_TO_EDIT_EXAM,
  EDIT_EXAM_SUCCESS,
} from "../types";

const ExamState = (props) => {
  const initialState = {
    exams: [],
    lecturerExams: [],
    exam: null,
    newExamQuestions: [],
    error: null,
  };

  const [state, dispatch] = useReducer(ExamReducer, initialState);

  // MongoDB Actions

  // add new question
  async function addExam(exam) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // console.log("logging from state side");
    try {
      const res = await axios.post("/api/v1/exams", exam, config);
      // console.log(res);

      dispatch({
        type: ADD_EXAM_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: ADD_EXAM_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // get all exams
  async function getExams() {
    // console.log("logging from state side");
    try {
      const res = await axios.get("/api/v1/exams");
      // console.log(res.data.data.data);

      dispatch({
        type: GET_EXAMS_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_EXAMS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }
  // get all lecturer exams
  async function getLecturerExams(userId) {
    const searchParams = userId ? "?createdBy=" + userId : "";
    // console.log("logging from state side");
    try {
      const res = await axios.get(`/api/v1/exams${searchParams}`);
      // console.log(res.data.data.data);

      dispatch({
        type: GET_LECTURER_EXAMS_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_LECTURER_EXAMS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // get one exam
  async function getExam(examId) {
    // console.log("logging from state side");
    try {
      const res = await axios.get(`/api/v1/exams/${examId}`);
      // console.log(res.data.data.data);

      dispatch({
        type: GET_EXAM_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_EXAM_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // edit exam
  async function editExam(examId, exam) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // console.log("logging from state side");
    try {
      const res = await axios.patch(`/api/v1/exams/${examId}`, exam, config);
      // console.log(res.data.data.data);

      dispatch({
        type: EDIT_EXAM_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_EXAM_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  function addQuestionsToEditExam(questionsIds) {
    // console.log(questionsIds);
    dispatch({
      type: ADD_QUESTIONS_TO_EDIT_EXAM,
      payload: questionsIds,
    });
  }
  function addQuestionToExam(questionId) {
    if (state.newExamQuestions.includes(questionId)) return;
    dispatch({
      type: ADD_QUESTION_TO_EXAM,
      payload: questionId,
    });
  }
  function removeQuestionFromExam(questionId) {
    if (!state.newExamQuestions.includes(questionId)) return;
    dispatch({
      type: REMOVE_QUESTION_FROM_EXAM,
      payload: questionId,
    });
  }

  return (
    <ExamContext.Provider
      value={{
        exams: state.exams,
        lecturerExams: state.lecturerExams,
        exam: state.exam,
        newExamQuestions: state.newExamQuestions,
        error: state.error,
        addExam,
        getExam,
        editExam,
        getExams,
        addQuestionToExam,
        addQuestionsToEditExam,
        removeQuestionFromExam,
        getLecturerExams,
      }}
    >
      {props.children}
    </ExamContext.Provider>
  );
};

export default ExamState;
