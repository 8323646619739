import { arrayRemove } from "../../utils/arrayRemove";

import {
  ADD_REVISION_SUCCESS,
  ADD_REVISION_FAIL,
  GET_REVISIONS_SUCCESS,
  GET_REVISIONS_FAIL,
  GET_REVISION_SUCCESS,
  GET_REVISION_FAIL,
  ADD_QUESTION_TO_REVISION,
  REMOVE_QUESTION_FROM_REVISION,
  LOGOUT,
  GET_LECTURER_REVISIONS_SUCCESS,
  GET_LECTURER_REVISIONS_FAIL,
  ADD_QUESTIONS_TO_EDIT_REVISION,
  EDIT_REVISION_SUCCESS,
} from "../types";

const RevisionReducer = (state, action) => {
  switch (action.type) {
    case ADD_REVISION_SUCCESS:
    case GET_REVISIONS_SUCCESS:
      return {
        ...state,
        // exams: [action.payload, ...state.exams],
        revisions: action.payload,
      };
    case EDIT_REVISION_SUCCESS:
      return {
        ...state,
        lecturerRevisions: [action.payload, ...state.lecturerRevisions].find(
          (exam) => exam._id !== action.payload._id
        ),
        // exams: action.payload,
      };
    case GET_LECTURER_REVISIONS_SUCCESS:
      return {
        ...state,
        lecturerRevisions: action.payload,
      };
    case GET_LECTURER_REVISIONS_FAIL:
      return {
        ...state,
        lecturerRevisions: [],
      };
    case GET_REVISION_SUCCESS:
      return {
        ...state,
        revision: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        revisions: null,
      };
    case ADD_QUESTION_TO_REVISION:
      return {
        ...state,
        newRevisionQuestions: [action.payload, ...state.newRevisionQuestions],
      };
    case ADD_QUESTIONS_TO_EDIT_REVISION:
      return {
        ...state,
        newRevisionQuestions: action.payload,
      };
    case REMOVE_QUESTION_FROM_REVISION:
      return {
        ...state,
        newRevisionQuestions: arrayRemove(
          state.newRevisionQuestions,
          action.payload
        ),
      };
    case ADD_REVISION_FAIL:
    case GET_REVISIONS_FAIL:
    case GET_REVISION_FAIL:
      return {
        ...state,
        revisions: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default RevisionReducer;
