import React from "react";
import LecExamsList from "../components/layout/LecExamsList";

const ExamsReportsPage = () => {
  return (
    <>
      <LecExamsList />
    </>
  );
};

export default ExamsReportsPage;
