import {
  ADD_QUESTION_SUCCESS,
  ADD_QUESTION_FAIL,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_FAIL,
} from "../types";

const QuestionReducer = (state, action) => {
  switch (action.type) {
    case ADD_QUESTION_SUCCESS:
    case GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        questions: action.payload,
      };
    case ADD_QUESTION_FAIL:
    case GET_QUESTIONS_FAIL:
      return {
        ...state,
        questions: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default QuestionReducer;
