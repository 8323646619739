import React from "react";
import useRevisionAns from "../../hooks/useRevisionAns";
import ExamControlButton from "../reusableComponents/ExamControlButton";

const ReviewQuestion = ({
  question,
  onChange,
  disabled,
  selected,
  correct,
  choose,
}) => {
  const { undoRevisionAnswer } = useRevisionAns();
  const options = Object.keys(question.choices);

  return (
    <div className="accordion">
      {/* prevent user text selection for copy/paste by using className="select-none" */}
      <div className="select-none">
        <label htmlFor={question._id} className="accordion__label">
          <li className="bg-veryLightGray  p-2 rounded-lg shadow-md w-full mt-3">
            <div className="font-bold">{question.question} </div>
            <div className="px-4">
              {options.map((option) => (
                <label
                  key={option}
                  htmlFor={option}
                  className={`block border border-gray-300 rounded-md mt-2 p-1 ${
                    disabled ? "" : "hover:bg-gray-100 cursor-pointer"
                  } ${option === selected ? "bg-brightRedSupLight" : ""} ${
                    option === correct ? "bg-green-200" : ""
                  }`}
                >
                  <input
                    type="radio"
                    id={option}
                    name={option}
                    value={option}
                    className="hidden"
                    onChange={onChange}
                    disabled={disabled}
                  />
                  <span className="accordion__span">
                    {option.toUpperCase()}. {question?.choices[option]}
                  </span>
                </label>
              ))}
            </div>
          </li>
        </label>
        <div className="accordion__content"></div>
      </div>
      {/* <div className="flex justify-end mt-3">
        <ExamControlButton
          text={"Undo"}
          bgColor={" bg-brightRedLight hover:bg-brightRed"}
          onClick={() => undoRevisionAnswer(question._id)}
        />
      </div> */}
    </div>
  );
};

export default ReviewQuestion;
