import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import useRevision from "../../hooks/useRevision";
import ExamDetails from "./ExamDetails";
import RevisionQuestions from "./RevisionQuestions";

const RevisionPaper = () => {
  const { revision, getRevision } = useRevision();
  const { id } = useParams();
  const effectRan = useRef();
  useEffect(() => {
    // if (effectRan.current === false) {
    getRevision(id);
    return () => {
      // effectRan.current = true;
    };
    // }
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      {/* {exam && <ExamDetails exam={exam} />} */}
      {revision && (
        <RevisionQuestions
          exam={revision}
          questions={revision.questions}
          examId={revision._id}
          title={revision.title}
        />
      )}
    </div>
  );
};

export default RevisionPaper;
