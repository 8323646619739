import React from "react";

import Footer from "../components/layout/Footer";
import Navbar from "../components/layout/Navbar";

import PrivacyPolicy from "./../components/layout/PrivacyPolicy";

const PrivacyPolicyPage = () => {
  return (
    <div>
      <Navbar />
      <PrivacyPolicy />
      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;
