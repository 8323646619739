import React, { useContext } from "react";

import WelcomeBanner from "../components/layout/WelcomeBanner";
import AuthContext from "../context/auth/AuthContext";

import RevisionPaper from "../components/layout/RevisionPaper";

const RevisionPage = () => {
  const authContext = useContext(AuthContext);

  return (
    <>
      {/* Welcome banner */}
      <WelcomeBanner user={authContext.user} msg={"Exam in progress:"} />
      <RevisionPaper user={authContext.user} />
    </>
  );
};

export default RevisionPage;
