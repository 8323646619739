import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useRevisionAns from "../../hooks/useRevisionAns";
import { letterGradeLMMU } from "../../utils/letterGradeLMMU";
import CountDownTimer from "../reusableComponents/CountDownTimer";
import ExamControlButton from "../reusableComponents/ExamControlButton";
import AbandonExamModal from "./AbandonExamModal";
import ExamDetails from "./ExamDetails";
import ExamQuestion from "./ExamQuestion";
import Revision_Question from "./Revision_Question";
import StartExamModal from "./StartExamModal";
import SubmitExamModal from "./SubmitExamModal";

const RevisionQuestions = ({ exam, questions, examId, title }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { newRevisionAnswers, addRevisionAnswerSheet, abandonRevision } =
    useRevisionAns();

  const [showStartExamModal, setShowStartExamModal] = useState(false);
  const [showSubmitExamModal, setShowSubmitExamModal] = useState(false);
  const [showAbandonExamModal, setShowAbandonExamModal] = useState(false);

  const [examAnswerSheet, setExamAnswerSheet] = useState({
    title,
    examId,
    userId: user._id,
    answers: [],
    numQuestions: 0,
    numCorrectAnswers: 0,
    numWrongAnswers: 0,
    unattempted: 0,
    score: 0,
    grade: "",
    startedAt: "",
    finishedAt: "",
  });

  const [examAnswers, setExamAnswers] = useState({
    questionId: "",
    question: "",
    choices: {},
    correctAnswer: "",
    choosenAnswer: "",
    explanation: "",
    correct: false,
    disabled: false,
    selected: "",
  });

  // variables
  // const [questionNum, setQuestionNum] = useState(0);

  // current question
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const numQuestions = questions.length;

  // keeps track of the current question
  let key = questions[currentQuestion]._id;
  let question = questions[currentQuestion];

  // set variables for detemining if a question is answered
  let newDisabled =
    newRevisionAnswers !== [] &&
    newRevisionAnswers.find((quest) => quest.questionId === key)
      ? newRevisionAnswers.find((quest) => quest.questionId === key).disabled
      : false;

  // set variables for which choice was selected
  let newSelected =
    newRevisionAnswers !== [] &&
    newRevisionAnswers.find((quest) => quest.questionId === key)
      ? newRevisionAnswers.find((quest) => quest.questionId === key).selected
      : "";

  // handles next question button logic
  const handleNext = () => {
    if (currentQuestion + 1 < numQuestions) {
      setCurrentQuestion(currentQuestion + 1);

      key = questions[currentQuestion]._id;
      question = questions[currentQuestion];
    } else {
      setShowSubmitExamModal(true);
      // console.log(showSubmitExamModal);
    }
  };

  // handles previous question button logic
  const handlePrev = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);

      key = questions[currentQuestion]._id;
      question = questions[currentQuestion];
    } else {
    }
  };

  // keeps track of changes in the choices options
  const onChange = (e) => {
    // console.log(e.target.value);
    if (e.target.value === question.answer) {
      setExamAnswers({
        ...examAnswers,
        questionId: question._id,
        question: `${currentQuestion + 1}. ${question.question}`,
        choosenAnswer: e.target.value,
        selected: e.target.value,
        correct: true,
        disabled: true,
        correctAnswer: question.answer,
        choices: question.choices,
        explanation: question.explanation,
      });
    } else {
      setExamAnswers({
        ...examAnswers,
        questionId: question._id,
        question: `${currentQuestion + 1}. ${question.question}`,
        choosenAnswer: e.target.value,
        selected: e.target.value,
        correct: false,
        disabled: true,
        correctAnswer: question.answer,
        choices: question.choices,
        explanation: question.explanation,
      });
    }

    setExamAnswers((state) => {
      if (
        !newRevisionAnswers.find(
          (o) => o.questionId === questions[currentQuestion]._id
        )
      ) {
        newRevisionAnswers.push(state);
      }

      return state;
    });

    console.log(newRevisionAnswers);
  };

  let attempted =
    newRevisionAnswers.filter((obj) => obj.correct === true).length +
    newRevisionAnswers.filter((obj) => obj.correct === false).length;

  // submit exam answer sheet
  const handleSubmit = () => {
    // calculate score variables
    const numCorrectAns = newRevisionAnswers.filter(
      (obj) => obj.correct === true
    ).length;
    const numWrongAns = newRevisionAnswers.filter(
      (obj) => obj.correct === false
    ).length;
    attempted = numCorrectAns + numWrongAns;
    const numUnattempted = numQuestions - attempted;
    const percetageScore = ((numCorrectAns / numQuestions) * 100).toFixed(0);

    setExamAnswerSheet({
      ...examAnswerSheet,
      answers: newRevisionAnswers,
      numQuestions: numQuestions,
      numCorrectAnswers: numCorrectAns,
      numWrongAnswers: numWrongAns,
      unattempted: numUnattempted,
      score: percetageScore,
      grade: letterGradeLMMU(percetageScore),
      finishedAt: Date.now(), // can add (+ 2 * 1000 * 60 * 60) zambian time
    });
    setExamAnswerSheet((state) => {
      if (newRevisionAnswers === []) return;
      addRevisionAnswerSheet(state);
      return state;
    });

    setTimeout(() => {
      navigate("/students/revisionresults", { replace: true });
    }, 1000);
  };

  // calculate finish target time
  // let finishingTime;
  const [startExamTimer, setStartExamTimer] = useState(false);

  const handleStart = () => {
    setStartExamTimer(true);

    setExamAnswerSheet({
      ...examAnswerSheet,
      startedAt: Date.now(), // can add (+ 2 * 1000 * 60 * 60) zambian time
    });

    handleCloseStartExamModal();
  };

  const handleAbort = () => {
    abandonRevision();
    navigate("/students/revise", { replace: true });
  };

  const handleCloseStartExamModal = () => setShowStartExamModal(true);
  const handleCloseSubmitExamModal = () => setShowSubmitExamModal(false);
  const handleCloseAbandonExamModal = () => setShowAbandonExamModal(false);

  const handleShowSubmitExamModal = () => setShowSubmitExamModal(true);
  const handleShowAbandonExamModal = () => setShowAbandonExamModal(true);

  const handleSubmitFromTimer = () => {
    // calculate score variables
    const numCorrectAns = newRevisionAnswers.filter(
      (obj) => obj.correct === true
    ).length;
    const numWrongAns = newRevisionAnswers.filter(
      (obj) => obj.correct === false
    ).length;
    attempted = numCorrectAns + numWrongAns;
    const numUnattempted = numQuestions - attempted;
    const percetageScore = ((numCorrectAns / numQuestions) * 100).toFixed(0);

    setExamAnswerSheet({
      ...examAnswerSheet,
      answers: newRevisionAnswers,
      numQuestions: numQuestions,
      numCorrectAnswers: numCorrectAns,
      numWrongAnswers: numWrongAns,
      unattempted: numUnattempted,
      score: percetageScore,
      grade: letterGradeLMMU(percetageScore),
      finishedAt: Date.now(), // can add (+ 2 * 1000 * 60 * 60) zambian time
    });
    setExamAnswerSheet((state) => {
      if (newRevisionAnswers === []) return;
      addRevisionAnswerSheet(state);
      return state;
    });

    setTimeout(() => {
      navigate("/students/results", { replace: true });
    }, 1000);
  };

  return (
    <div>
      <ExamDetails
        exam={exam}
        question={currentQuestion}
        setCurrentQuestion={setCurrentQuestion}
        newExamAnswers={newRevisionAnswers}
        numQuestions={numQuestions}
        questionNum={currentQuestion + 1}
        disabled={newDisabled}
        onClick={handleShowSubmitExamModal}
        onAbandon={handleShowAbandonExamModal}
        // onClick={handleSubmit}
      />
      <div className="flex flex-row justify-between">
        <h3 className="ml-2 text-lg font-bold md:block">
          Question {currentQuestion + 1}
        </h3>
        <h3 className="ml-2 text-lg text-brightRed font-bold md:block">
          {startExamTimer && (
            // <CountDownTimer endTime={finishingTime} toDo={handleSubmit} />
            <CountDownTimer
              endTime={new Date().getTime() + exam.duration * 1000 * 60}
              toDo={() => handleSubmitFromTimer()}
            />
          )}
        </h3>
      </div>

      <ul className="list">
        <Revision_Question
          key={key}
          question={question}
          onChange={onChange}
          disabled={newDisabled}
          selected={newSelected}
          // undo={handleUndo}
        />
      </ul>
      <div className="flex justify-evenly mt-8">
        <ExamControlButton onClick={handlePrev} text={"Prev"} />
        {/* <ExamControlButton
          text={"Undo"}
          bgColor={" bg-brightRedLight hover:bg-brightRed"}
          onClick={() => {}}
        /> */}
        <ExamControlButton onClick={handleNext} text={"Next"} />
      </div>
      <div className="">
        <SubmitExamModal
          onClose={handleCloseSubmitExamModal}
          visible={showSubmitExamModal}
          numAnswered={attempted}
          numQuestions={numQuestions}
          onSubmit={handleSubmit}
        />
        <AbandonExamModal
          onClose={handleCloseAbandonExamModal}
          visible={showAbandonExamModal}
          numAnswered={attempted}
          numQuestions={numQuestions}
          onAbandon={handleAbort}
        />
        <StartExamModal
          onClose={handleAbort}
          visible={showStartExamModal}
          title={title}
          numQuestions={numQuestions}
          onStart={handleStart}
        />
      </div>
    </div>
  );
};

export default RevisionQuestions;
