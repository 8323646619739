import { useEffect } from "react";
import useAuth from "../hooks/useAuth";

const NotVerifiedPage = () => {
  const { loadUser, user } = useAuth();
  useEffect(() => {
    loadUser();

    // eslint-disable-next-line
  }, []);

  let firstName;

  if (user) firstName = user.name.split(" ")[0];
  return (
    <div>
      <h3>Hi {firstName}</h3>
      <p>
        Your account is not yet verified. Kindly check your email for a
        verification link.
      </p>
      <p>
        If this account is not verified within 24hrs, it will be erased. You can
        then later register again.
      </p>
      <div className="bottomBox"></div>
    </div>
  );
};

export default NotVerifiedPage;
