import React from "react";
// import lib from "xss-clean/lib";

const Pagination = ({
  questionsPerPage,
  totalQuestions,
  paginate,
  next,
  prev,
  currentPage,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalQuestions / questionsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="mt-6">
      <ul className="flex flex-row overflow-auto">
        <li className="">
          <button
            onClick={next}
            className=" h-12 px-4 rounded-l-full border-2 border-r-0 border-brightRedSupLight hover:bg-brightRedSupLight"
          >
            <svg className="w-4 h-4 fill-current" viewBox=" 0 0 20 20 ">
              {/* <path
                d="M12.707 5.293a1 1 0 010 1.414L9.414 1013.293 3.293a1 1 0 01-1.s414 1.4141-4-4a1 1 0 010-1.41414-4a1 1 0 011.414 0z"
                clipRule="evenodd"
                fillRule="evenodd"
              ></path> */}
            </svg>
          </button>
        </li>
        {pageNumbers.map((number) => (
          <li key={number} className="">
            <button
              onClick={() => paginate(number)}
              className={` h-12 w-12 border-2 border-r-0 border-brightRedSupLight cursor-pointer ${
                currentPage === number && "bg-brightRedSupLight"
              }`}
            >
              {number}
            </button>
          </li>
        ))}
        <li className="">
          <button
            onClick={prev}
            className=" h-12 px-4 rounded-r-full border-2 border-r-0 border-brightRedSupLight hover:bg-brightRedSupLight"
          >
            <svg className="w-4 h-4 fill-current" viewBox=" 0 0 20 20 "></svg>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
