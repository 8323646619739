import React, { useReducer } from "react";
// import { useNavigate } from "react-router-dom";
import axios from "axios";

import RegExamContext from "./RegExamContext";
import RegExamReducer from "./RegExamReducer";

import {
  ADD_REG_EXAM_SUCCESS,
  ADD_REG_EXAM_FAIL,
  GET_REG_EXAMS_SUCCESS,
  GET_REG_EXAMS_FAIL,
  GET_EXAM_REGS_SUCCESS,
  GET_EXAM_REGS_FAIL,
  DELETE_EXAM_REG_SUCCESS,
  DELETE_EXAM_REG_FAIL,
} from "../types";

const RegExamState = (props) => {
  // const navigate = useNavigate();

  const initialState = {
    registerExams: [],
    examRegistrations: [],
    error: null,
  };

  const [state, dispatch] = useReducer(RegExamReducer, initialState);

  // MongoDB Actions

  // add new question
  async function addRegisterExam(registerExam) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // console.log("logging from state side");
    try {
      const res = await axios.post(
        "/api/v1/registerforexams",
        registerExam,
        config
      );

      // console.log(res.data.data.data);

      // for inApp display of results
      dispatch({
        type: ADD_REG_EXAM_SUCCESS,
        payload: res.data.data.data,
      });

      // setTimeout(() => {
      //   navigate("/exams", { replace: true });
      // }, 1000);
    } catch (error) {
      // console.log(error);
      dispatch({
        type: ADD_REG_EXAM_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // get all exam
  async function getAllRegExams(userId) {
    const searchParams = userId ? "?userId=" + userId : "";
    // console.log("logging from state side");
    try {
      const res = await axios.get(`/api/v1/registerforexams${searchParams}`);
      // console.log(res.data.data.data);

      dispatch({
        type: GET_REG_EXAMS_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_REG_EXAMS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }
  // get all lecturer exams
  async function getAllExamRegs(examId) {
    const searchParams = examId ? "?examId=" + examId : "";
    // console.log("logging from state side");
    try {
      const res = await axios.get(`/api/v1/registerforexams${searchParams}`);
      // console.log(res.data.data.data);

      dispatch({
        type: GET_EXAM_REGS_SUCCESS,
        payload: res.data.data.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_EXAM_REGS_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  // delete registered student
  async function deRegisterStudent(registrationId) {
    try {
      // const res =
      await axios.delete(`/api/v1/registerforexams/${registrationId}`);
      // console.log(res.data.data.data);

      dispatch({
        type: DELETE_EXAM_REG_SUCCESS,
        payload: registrationId,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: DELETE_EXAM_REG_FAIL,
        // payload: error.responce.data.error,
      });
    }
  }

  return (
    <RegExamContext.Provider
      value={{
        registerExams: state.registerExams,
        examRegistrations: state.examRegistrations,
        error: state.error,
        addRegisterExam,
        getAllExamRegs,
        getAllRegExams,
        deRegisterStudent,
      }}
    >
      {props.children}
    </RegExamContext.Provider>
  );
};

export default RegExamState;
