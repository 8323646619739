import React, { useState, useContext, useEffect } from "react";

import AlertContext from "./../../context/alert/AlertContext";
// import ChartOfAccountsState from "../context/chartOfAccounts/ChartOfAccountsState";

import Select from "react-select";

import Alerts from "./Alerts";
import useAuth from "../../hooks/useAuth";
import ExamControlButton from "../reusableComponents/ExamControlButton";

const ApproveLecturer = () => {
  const [text, setText] = useState("");

  const [isAddingTransaction, setIsAddingTransaction] = useState(false);

  const { setAlert } = useContext(AlertContext);

  // get signed in user

  const { user, approveLecturer, unApprovedLecturers, getUnApprovedLecturers } =
    useAuth();

  useEffect(() => {
    getUnApprovedLecturers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddingTransaction]);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!text) {
      setIsAddingTransaction(!isAddingTransaction);
      return;
    }

    const details = {
      newMemberId: text,
      userId: user._id,
    };

    try {
      await approveLecturer(details);

      // console.log(res);

      setIsAddingTransaction(!isAddingTransaction);
      setText("");

      setAlert("New lecturer approved", "success");
    } catch (err) {
      console.log(err);
      setAlert("Something went wrong, try again later.", "danger");
    }
  };

  const toggleForm = () => {
    setIsAddingTransaction(!isAddingTransaction);
  };

  const handFromChange = (e) => {
    if (e) {
      setText(e._id);
    }
  };

  return (
    <div className="  min-h-screen flex flex-col  py-12 px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md ">
        <h3 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Approve Lecturer
        </h3>
        <Alerts />
        {isAddingTransaction && (
          <form onSubmit={onSubmit}>
            <div className="form-control">
              <label htmlFor="from">Select a new lecturer to approve...</label>
              <Select
                defaultValue={text}
                options={unApprovedLecturers}
                onChange={handFromChange}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option._id}
                isClearable
                isSearchable
              />
            </div>

            <div className="flex justify-center items-center mt-6">
              <ExamControlButton text={"Approve lecturer"} />
            </div>
          </form>
        )}

        <div className="flex justify-center my-6 mx-auto items-center">
          <ExamControlButton
            text={isAddingTransaction ? "Cancel" : "Approve lecturer"}
            onClick={toggleForm}
            bgColor={isAddingTransaction ? "bg-gray-200" : ""}
            textColor={isAddingTransaction ? "text-gray-800" : ""}
          />
        </div>
      </div>
    </div>
  );
};

export default ApproveLecturer;
