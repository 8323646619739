import React from "react";
import { Link } from "react-router-dom";

const Testimonials = () => {
  return (
    <div className="max-w-6xl px-5 mx-auto mt-32 text-center">
      {/* Heading */}
      <h2 className="text-4xl font-bold text-center">
        What's Different About Examaholics?
      </h2>
      <div className=" flex flex-col mt-24 md:flex-row md:space-x-6">
        {/* Testimonial 1 */}
        <div className="flex flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:w-1/3">
          <img src="" alt="" className="w-16 -mt-14" />
          <h5 className="text-lg font-bold">Chanda Mulenga</h5>
          <p className="text-sm text-darkGrayishBlue">
            "Examaholics has supercharged my workflow. The ability to maintain
            visibility on larger milestones at all the times keeps me
            motivated."
          </p>
        </div>
        {/* Testimonial 2 */}
        <div className="hidden flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:flex md:w-1/3">
          <img src="" alt="" className="w-16 -mt-14" />
          <h5 className="text-lg font-bold">Moono Hantobolo</h5>
          <p className="text-sm text-darkGrayishBlue">
            "I have stopped buying question papers and pamphlets since using
            Examaholics. There is no more confusion on how to prepare for exams
            and I'm so much focused."
          </p>
        </div>
        {/* Testimonial 3 */}
        <div className="hidden flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:flex md:w-1/3">
          <img src="" alt="" className="w-16 -mt-14" />
          <h5 className="text-lg font-bold">Mapalo Mwanalusaka</h5>
          <p className="text-sm text-darkGrayishBlue">
            "It is as advertised. From exam preparations reporting students
            performance, everything is automated. My work as a lecturer simple
            cut to half."
          </p>
        </div>
      </div>
      {/* Button */}
      <div className="my-16 ">
        {/* <a
          href="#"
          className=" p-3 px-6 pt-2 text-white bg-brightRed rounded-full baseline hover:bg-brightRedLight"
        >
          Get Started
        </a> */}
        <Link
          to="/login"
          className="p-3 px-6 pt-2 text-white bg-brightRed rounded-full baseline hover:bg-brightRedLight"
        >
          Get Started
        </Link>
      </div>
    </div>
  );
};

export default Testimonials;
