import { useParams } from "react-router-dom";

import ExamRegStudentsList from "./../components/layout/ExamRegStudentsList";

const ExamRegisteredPage = () => {
  const { id } = useParams();

  return (
    <>
      <ExamRegStudentsList examId={id} />
    </>
  );
};

export default ExamRegisteredPage;
