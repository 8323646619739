import React, { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import Pagination from "../reusableComponents/Pagination";

import useQuestion from "./../../hooks/useQuestion";
import Question from "./Question";
import CreateExamQuestion from "./CreateExamQuestion";

const QuestionList = () => {
  const { questions, getQuestions, getLecQuestions } = useQuestion();
  const { user } = useAuth();
  const [query, setQuery] = useState("");
  const [searchKeys, setSearchKeys] = useState(["question", "course", "topic"]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [questionsPerPage] = useState(10);

  useEffect(() => {
    setLoading(true);
    if (user.isApproved) {
      getQuestions();
    } else {
      getLecQuestions(user._id);
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Search function
  const search = (entries) => {
    return (
      entries &&
      entries.filter(
        // (entry) => entry.topic.toLowerCase().indexOf(query.toLowerCase()) > -1
        (entry) =>
          searchKeys.some(
            (key) =>
              entry[key].toString().toLowerCase().indexOf(query.toLowerCase()) >
              -1,
          ),
      )
    );
  };

  // get current questions
  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const searchedQuestions = search(questions);
  const currentQuestions = searchedQuestions?.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion,
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const next = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const prev = () => {
    currentPage > 1 && setCurrentPage((prevPage) => prevPage - 1);
  };

  if (loading) return <h2>Loading...</h2>;

  const keys = questions && questions[0] && Object.keys(questions[0]);

  return (
    <div>
      <div className="bg-veryLightGray border rounded-lg p-4 mt-3 shadow-md">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Search</h3>
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className="my-2 focus:ring-brightRedLight focus:border-brightRedLight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
        {keys &&
          // eslint-disable-next-line
          keys.map((key) => {
            if (
              key !== "images" &&
              key !== "_id" &&
              key !== "choices" &&
              key !== "answer" &&
              key !== "explanation" &&
              key !== "createdBy"
            )
              return (
                <label key={key} className="pr-6">
                  <input
                    className=""
                    type="checkbox"
                    checked={searchKeys.includes(key)}
                    onChange={(e) => {
                      const checked = searchKeys.includes(key);
                      setSearchKeys((prev) =>
                        checked
                          ? prev.filter((sk) => sk !== key)
                          : [...prev, key],
                      );
                    }}
                  />
                  <span className="px-2 capitalize">{key}</span>
                </label>
              );
          })}
      </div>
      <div>
        {/* <h3>Questions</h3> */}
        <ul className="list">
          {currentQuestions &&
            currentQuestions.map((question) => (
              <CreateExamQuestion key={question._id} question={question} />
            ))}
        </ul>
        <Pagination
          questionsPerPage={questionsPerPage}
          totalQuestions={searchedQuestions?.length}
          paginate={paginate}
          next={next}
          prev={prev}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default QuestionList;
